.top {
  padding: .75rem 0;
  background-color: var(--color-primary, #3b4143);
  color: white;
  font-size: .8rem;
  line-height: 1;
}

.icon {
  margin-right: .25rem;
  color: var(--color-secondary, #ffb332);
  font-size: 1rem;
}
