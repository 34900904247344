.block {
  color: var(--dshb-color-primary, #3b4143);
}

.title {
  color: inherit;
  font-size: 1rem;
  font-weight: 500;
}

.hint {
  margin-bottom: .75rem;
  color: inherit;
  font-size: .9rem;
  font-weight: 400;
  line-height: 1;
}

.range {
  color: inherit;
  font-size: 1.25rem;
  font-weight: 700;
}
