.banner {
  padding: 2.5rem 0;
  overflow-x: hidden;
  background-size: cover;
  background-position: center;
}

.banner__heading {
  margin-bottom: 1rem;
  color: var(--color-primary, #073547);
  font-size: 2rem;
  font-weight: 500;
  line-height: 1.2;
}

.banner__text {
  margin-bottom: 2rem;
  padding-right: 1rem;
  color: #2f3434;
  font-family: 'Open Sans', sans-serif;
  line-height: 1.7;
}

.banner__show-link {
  display: inline-flex;
}
.banner__show-link-text {
  margin-left: .5rem;
  color: var(--color-accent, #ffb332);
  font-size: 1.1rem;
  font-weight: 700;
  text-decoration: underline;
}

.banner__image-wrapper {
  text-align: right;
  margin-bottom: 2rem;
}
.banner__image {
  width: 90%;
}
.banner__image_xl {
  display: none;
}

@media (min-width: 576px) {
  .banner__image-wrapper {
    position: absolute;
    top: 0;
    left: 68%;
    display: flex;
    align-items: center;
    height: 100%;
  }
  .banner__image {
    width: auto;
    height: 72vw;
    max-height: 100%;
    margin-top: 1rem;
    margin-bottom: -.5rem;
  }
  .banner__text {
    padding-right: 4rem;
  }
}
@media (min-width: 768px) {
  .banner {
    padding: 3rem 0 3.5rem;
  }
  .banner__image-wrapper {
    left: 60%;
  }
}
@media (min-width: 992px) {
  .banner {
    padding: 5rem 0;
  }
  .banner__image-wrapper {
    left: 50%;
  }
  .banner__heading {
    font-size: 2.75rem;
  }
  .banner__text {
    font-size: 1.25rem;
  }
}
@media (min-width: 1200px) {
  .banner__image-wrapper {
    left: 50%;
    right: 0;
    justify-content: flex-end;
  }
}
@media (min-width: 1600px) {
  .banner__image-wrapper {
    justify-content: flex-start;
  }
  .banner__image {
    display: none;
  }
  .banner__image_xl {
    display: block;
    max-width: 90%;
    max-height: 100%;
  }
}
