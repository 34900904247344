.footer {
  padding-top: 2rem;
  padding-bottom: 1rem;
  background-color: var(--color-primary, #3b4143);
  color: white;
}

.paragraph {
  margin-bottom: .75rem;
  font-size: .8rem;
  line-height: 1.2;
}

.link {
  color: var(--color-secondary, #ffb332) !important; /* hover */
  text-decoration: underline;
}

.social_link {
  color: var(--color-secondary, #ffb332) !important;  /* hover */
  font-size: 2.2rem;
}
