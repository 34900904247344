/* menu */
.menu {
  display: inline-flex;
  flex-direction: column;
  list-style: none;
  margin: 0;
  padding: 0;
}

.menu__item {
  padding-top: 1.25rem;
  line-height: 1;
}
.menu__item::after {
  content: '';
  display: block;
  width: 1.25rem;
  height: 4px;
  border-radius: 10px;
  margin-top: .4rem;
  margin-left: auto;
}
.menu__item_active::after {
  background-color: var(--color-secondary, #ffb332);
}

.menu__link {
  padding: .5rem 0;
  color: #2F3434;
  font-size: 1rem;
  font-weight: 500;
}
.menu__link:hover {
  color: #2F3434;
  text-decoration: none;
}
.menu_inverse .menu__link {
  color: white !important;
}

@media (min-width: 992px) {
  .menu {
    flex-direction: row;
  }
  .menu__item:not(:last-child) {
    margin-right: 2rem;
  }
  .menu__item::after {
    margin-top: .5rem;
    margin-right: auto;
  }
}
