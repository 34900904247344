.list-select {
  padding-bottom: .5rem !important;
}

.list-select__list {
  height: 100%;
  border-top: 1px solid #ebebeb;
  margin: 0;
  padding-left: 0;
  padding-right: 1rem;
  list-style: none;
  overflow-y: scroll;
}

.list-select__option,
.list-select__option_active {
  border-bottom: 1px solid #ebebeb;
  padding: 1rem 1.5rem;
  color: var(--predictor-color-primary, #3b4143);
  font-size: 1.1rem;
  font-weight: 500;
  line-height: 1;
  text-decoration: none;
  cursor: pointer;
}
.list-select__option_active {
  background-color: var(--predictor-color-accent, #ffb332);
  color: white;
}

/* this works poorly on touchscreens */
/*.list-select__option:active,
.list-select__option_active:active {
  box-shadow: inset 0 1px 3px 0 rgba(0, 0, 0, 0.5);
}*/

.list-select__search {
  position: relative;
  margin-bottom: 1rem;
}
.list-select__search-input {
  width: 100%;
  height: 3rem;
  border: none;
  border-radius: 3px;
  padding-left: 4rem;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.36);
  background-color: #fcfcfc;
  color: var(--predictor-color-primary, #3b4143);
}
.list-select__search-input::placeholder {
  color: #3b525d;
  font-weight: 500;
}
.list-select__search-icon {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 2rem;
  color: #3b525d;
}

@media (min-width: 576px) {
  .list-select {
    padding-bottom: .75rem !important;
  }
}

@media (min-width: 768px) {
  .list-select {
    padding-bottom: 1rem !important;
  }
}
