/* COPIED STYLES */

/* offer */
.image_wrapper {
  position: relative;
  height: 0;
  margin-bottom: 1rem;
  padding-bottom: 100%;
}
.image {
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}

.title {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  height: 2.5rem;
  color: var(--color-primary, #3b4143);
  font-size: 1.35rem;
  font-weight: 700;
  text-transform: uppercase;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.description {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
  text-align: left;
  height: 2.5rem;
  margin-bottom: .75rem;
  color: var(--color-primary, #3b4143);
}

.price_range {
  display: flex;
  align-items: center;
  color: #e6e6e6;
  font-size: 1.35rem;
  font-weight: 500;
}
.price_muted {
  display: inline-block;
  width: 6rem;
  height: 1.5rem;
  border-radius: 1rem;
  margin: 0 .5rem;
  background-color: #e6e6e6;
}
