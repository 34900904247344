.cookie_bar {
  position: fixed;
  z-index: 1;
  left: 0;
  right: 0;
  bottom: 0;
}

.container {
  text-align: center;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  box-shadow: -3px -2px 4px 0 rgba(0, 0, 0, 0.28);
  padding: .5rem .5rem 0;
  background-color: var(--color-primary);
  color: white;
}

.text_block {
  max-width: 40rem;
  margin: 0 auto .5rem;
}
.link {
  color: var(--color-secondary, #ffb332) !important;  /* hover */
  font-weight: 500;
  text-decoration: underline;
}

@media (min-width: 992px) {
  .container {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .text_block {
    margin: 0 1.5rem .5rem 0;
  }
}
