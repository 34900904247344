.layout {
  display: flex;
}
.left {
  display: none;
}
.right {
  flex: 1;
  padding: 0 1rem;
}

.navbar_wrapper {
  position: -webkit-sticky;
  position: sticky;
  z-index: 1000;
  top: 0;
}

.right_container {
  padding-top: 1rem;  /* remove when Toolbar appears */
  padding-bottom: 1rem;
}


@media (min-width: 768px) {
  .main {
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.26);
    padding: 1rem;
  }
}


@media (min-width: 992px) {
  .layout {
    min-height: 100vh;
  }
  .navbar_wrapper {
    display: none;
  }
  .left {
    display: flex;
    flex-direction: column;
    box-shadow: 2px 2px 4px 0 rgba(65, 65, 65, 0.49);
  }
  .right {
    padding: 0 1.5rem;
  }
  .right_container {
    display: flex;
    flex-direction: column;
    height: 100%;
    padding-top: 1.5rem;  /* remove when Toolbar appears */
    padding-bottom: 1.5rem;
  }
  .main {
    height: 100%;
    padding: 1rem 1.5rem;
  }
}


@media (min-width: 1200px) {
  .right {
    padding: 0 2rem;
  }
  .main {
    padding: 1rem 2rem;
  }
}
