.back {
  display: inline-flex;
  align-items: center;
  cursor: pointer;
}

.text {
  margin: 0 .5rem;
  color: #a5a5a5;
  font-weight: 500;
}
.back:hover > .text {
  text-decoration: underline;
}
