.terms-of-service {
  padding-top: 2rem;
  padding-bottom: 2rem;
}

.terms-of-service__separator {
  margin-bottom: 2rem;
}

@media (min-width: 992px) {
  .terms-of-service {
    max-width: 960px !important;  /* overriding bootstrap container */
    padding-top: 4rem;
    padding-bottom: 4rem;
  }
  .terms-of-service__separator {
    margin-bottom: 3rem;
  }
}
