/* offer */
.offer__image-wrapper {
  position: relative;
  height: 0;
  margin-bottom: 1rem;
  padding-bottom: 100%;
}
.offer__image {
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}

.offer__title {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 2.5rem;
  color: var(--color-primary, #073547);
  font-size: 1.35rem;
  font-weight: 700;
  text-transform: uppercase;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.offer__description {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
  text-align: center;
  height: 2.5rem;
  margin-bottom: .75rem;
  color: var(--color-primary, #073547);
}

.offer__price-range {
  display: flex;
  align-items: center;
  color: #e6e6e6;
  font-size: 1.35rem;
  font-weight: 500;
}
.offer__price_muted {
  display: inline-block;
  width: 6rem;
  height: 1.5rem;
  border-radius: 1rem;
  margin: 0 .5rem;
  background-color: #e6e6e6;
}

.offer {
  padding: 0 .25rem;
}
@media (min-width: 576px) {
  .offer {
    padding: 0 .5rem;
  }
  .offer__title {
    justify-content: flex-start;
  }
  .offer__description {
    text-align: left;
  }
}
@media (min-width: 992px) {
  .offer {
    padding: 0 .75rem;
  }
}
