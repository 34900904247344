.sidebar {
  height: 100%;
  padding: 1.5rem 1.5rem 0;
  background-color: var(--dshb-color-primary, #3b4143);
  color: white;
  font-size: .8rem;
}

.separator {
  width: 100%;
  border-top: 1px solid rgba(255, 255, 255, .25);
  margin: 0 auto 1rem;
}

.coming_soon_bold {
  color: var(--dshb-color-secondary, #ffb332);
  font-weight: 500;
  line-height: 1.25;
}
