.filters__heading {
  color: var(--color-primary, #073547);
  font-size: 1rem;
  font-weight: 500;
}
.filters__heading_disabled {
  color: gray;
}

.filters__category-button {
  width: 50%;
  height: 4rem;
  border: 1px solid white;
  background-color: var(--color-secondary, #ffb332);
}
.filters__category-image {
  max-width: 100%;
}

.filters__select-label {
  color: #2f3434;
  font-size: .9rem;
}

.filters__slider-block {
  display: flex;
  align-items: center;
}
.filters__slider-edge {
  padding-top: .25rem;
  color: #232323;
  font-size: .8rem;
}
.filters__slider-wrapper {
  flex: 1;
}
.filters__slider-block_disabled > .filters__slider-edge {
  color: gray;
}
