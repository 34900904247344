.marketplace-banner {
  position: relative;
  height: 55vh;
}

.marketplace-banner__image {
  width: 100%;
  height: 100%;  
  object-fit: cover;
}

.marketplace-banner__content {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: flex-end;
  padding-bottom: 1.5rem;
  color: white;
  font-size: 2rem;
  font-weight: 500;
  line-height: 1.2;
}

@media (min-width: 576px) {
  .marketplace-banner__content {
    align-items: center;
    text-align: center;
    width: 35rem;
    margin: auto;
    padding-bottom: 0;
  }
}

@media (min-width: 992px) {
  .marketplace-banner__content {
    width: 45rem;
    font-size: 2.75rem;
  }
}
