/* general menu item */
.item {
  display: flex;
  align-items: center;
  height: 2rem;
}

.item_image_wrapper {
  text-align: center;
  width: 1.5rem;
}
.item_image {
  max-width: 100%;
  max-height: 1.35rem;
}

.item_text {
  padding-left: 1.25rem;
}
.item:hover > .item_text {
  text-decoration: underline;
}


/* menu button */
.menu_button {
  display: block;
  width: 100%;
  border: none;
  padding: 0;
  background: none;
}
.menu_button:disabled {
  opacity: .5;
  cursor: not-allowed;
}
.menu_button:disabled > .item > .item_text {
  text-decoration: none;
}


/* menu button */
.menu_link {
  display: block;
  width: 100%;
  color: white !important;  /* hover */
}
