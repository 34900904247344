.form {
  display: grid;
  grid-template-columns: 1fr;
  row-gap: 2rem;
}

.checkbox_group {
  height: 10rem;
  overflow-y: scroll;
}

.group {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.group:not(:last-child) {
  margin-bottom: 1rem;
}

.label {
  margin-right: .5rem;
  margin-bottom: 0;
  color: var(--dshb-color-primary, #3b4143);
  font-weight: 500;
  white-space: nowrap;
}

.control_wrapper {
  width: 50%;
}
.control {
  max-width: 50%;
}

.checkbox_group_label {
  margin: .5rem 0 .75rem;
  color: var(--dshb-color-primary, #3b4143);
  font-weight: 500;
}

.checkbox_label {
  color: var(--dshb-color-primary, #3b4143);
  font-size: 1rem;
}

.submit_wrapper {
  display: inline-flex;
  flex-direction: column;
  justify-content: center;
}


@media (min-width: 768px) {
  .form {
    grid-template-columns: 1fr 1fr;
    column-gap: 2rem;
  }
}

@media (min-width: 1300px) {
  .form {
    grid-template-columns: repeat(3, 1fr);
    row-gap: 3rem;
    column-gap: 3rem;
  }

  .group:not(:last-child) {
    margin-bottom: 2rem;
  }
  .control {
    width: 10rem !important;
    max-width: none;
  }
  .control_wrapper {
    width: 10rem;
  }

  .checkbox_group {
    height: 9rem;
  }
}

@media (min-width: 1600px) {
  .form {
    grid-template-columns: repeat(4, 1fr);
    column-gap: 2rem;
  }
}
