.evaluator {
  padding-top: 2rem;
  padding-bottom: 2rem;
  background-color: white;
}

.title {
  color: var(--dshb-color-primary, #073547);
  font-size: 2rem;
  font-weight: 500;
  line-height: 1.2;
}

.separator {
  width: 100%;
  height: 0;
  border-top: 1px solid #e6e6e6;
  margin-bottom: 2.0rem;
}

@media (min-width: 992px) {
  .evaluator {
    padding-top: 4rem;
    padding-bottom: 4rem;
  }
  .title {
    font-size: 2.75rem;
  }
}
