.error-screen__content {
  display: block;
  text-align: center;
  overflow-y: scroll;
}

.error-screen__heading {
  margin-top: 1rem;
}

.error-screen__message-block {
  text-align: center;
  width: auto;
  max-width: 320px;
  margin: 2rem auto 1.5rem;
}
.error-screen__message-large {
  margin-bottom: 1.5rem;
}
.error-screen__message {
  display: inline;
}

.error-screen__contact-block {
  display: flex;
  flex-direction: column;
  margin-bottom: 2.5rem;
}
.error-screen__mail-link {
  color: var(--predictor-color-accent, #ffb332) !important;
  font-weight: 700;
  text-decoration: underline;
}
.error-screen__phone {
  color: var(--predictor-color-primary, #3b4143);
}
.error-screen__contact-separator {
  display: none;
  margin: 0 1rem;
  color: var(--predictor-color-primary, #3b4143);
}

@media (min-width: 576px) {
  .error-screen__content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .error-screen__heading {
    margin-top: 0;
  }
  .error-screen__message-block {
    max-width: none;
  }
  .error-screen__message {
    display: block;
  }
  .error-screen__contact-block {
    flex-direction: row;
    justify-content: center;
  }
  .error-screen__contact-separator {
    display: block;
  }
}
