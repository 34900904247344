.navbar-logo {
  position: relative;
}

.navbar-logo__image {
  width: 100%;
}

.navbar-logo__secondary-image {
  position: absolute;
  top: 105%;
  right: 0;
  height: .75rem;
}

@media (min-width: 768px) {
  .navbar-logo__secondary-image {
    height: .8rem;
  }
}
@media (min-width: 992px) {
  .navbar-logo__secondary-image {
    height: 1rem;
  }
}
