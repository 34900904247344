/* sign-up modal */
.sign-up-modal {
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
}

@media (min-width: 576px) {
  .sign-up-modal {
    max-width: 560px;
  }
}
@media (min-width: 768px) {
  .sign-up-modal {
    max-width: 720px;
  }
}
@media (min-width: 992px) {
  .sign-up-modal {
    max-width: 800px;
  }
}
