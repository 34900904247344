/* The container */
.checkbox {
  position: relative;
  display: inline-block;
  text-align: left;
  margin: 0;
  padding-left: 1.75rem;
  cursor: pointer;
}

/* Hide the browser's default checkbox */
.checkbox_input {
  position: absolute;
  left: 0;
  opacity: 0;
}

/* Create a custom checkbox */
.checkbox_checkmark {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
}

/* Create the checkmark/indicator */
.checkbox_checkmark:after {
  content: '\f0c8';
  color: var(--dshb-color-primary, #3b4143);
  font-family: 'Font Awesome 5 Free';
  font-weight: 400;
}

/* Show the checkmark when checked */
.checkbox_input:checked ~ .checkbox_checkmark:after {
  content: '\f14a';
  color: var(--dshb-color-primary, #3b4143);
  font-weight: 700;
}
