.wrapper {
  position: relative;
  width: 100%;
  height: 0;
  padding-bottom: 80%;
}

.plot {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.plot summary {
  z-index: 999 !important;
}
