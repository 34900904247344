.marketplace-alert {
  position: relative;
  border-radius: 10px;
  padding: 1.5rem;
  padding-right: 2.5rem;
  background-color: var(--color-primary, #073547);
  color: white;
}

.marketplace-alert__close-button {
  position: absolute;
  top: 0;
  right: 0;
  padding: .75rem 1rem;
  border: none;
  background: none;
  line-height: 1;
}

.marketplace-alert__text-block {
  margin-bottom: 1rem;
}

.marketplace-alert__link {
  color: var(--color-secondary, #ffb332) !important; /* hover */
  font-weight: 700;
  text-decoration: underline;
  text-transform: uppercase;
}

@media (min-width: 768px) {
  .marketplace-alert__close-button {
    top: .5rem;
    right: .5rem;
  }
}

@media (min-width: 1200px) {
  .marketplace-alert {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: 3rem;
    padding: .75rem 6rem .75rem 2.5rem;
  }

  .marketplace-alert__text-block {
    margin-bottom: 0;
  }

  .marketplace-alert__close-button {
    top: 50%;
    transform: translateY(-50%);
    right: 1rem;
  }
}
