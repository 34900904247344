.benefit-line {
  padding: 1rem 0;
  box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.5) inset;
  background-color: var(--color-primary, #073547);
}
.benefit-line_light {
  box-shadow: none;
  background-color: transparent;
}

.benefit-line__separator {
  width: 2.5rem;
  border-top: 1px solid rgba(255, 255, 255, 0.25);
  margin: 0 auto;
}
.benefit-line_light .benefit-line__separator {
  border-color: #eaeaea;
}

.benefit-line__item {
  margin-top: 2rem !important;
  margin-bottom: 2rem !important;
}

@media (min-width: 992px) {
  .benefit-line__container {
    display: flex;
    align-items: center;
    padding: 0;
  }
  .benefit-line__separator {
    width: 0;
    height: 2.5rem;
    border-top: none;
    border-right: 1px solid rgba(255, 255, 255, 0.25);
    margin: 0;
  }
}
