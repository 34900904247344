.navbar {
  display: flex;
  align-items: center;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.24);
  padding: 1.5rem 0;
  background-color: white;
}

.logo {
  height: 1.5rem;
}
