.label {
  margin-bottom: .5rem;
  color: var(--dshb-color-primary, #3b4143);
  font-weight: 500;
}

.counter_wrapper {
  display: flex;
}

.input {
  flex: 1;
  margin: 0 .5rem;
}
