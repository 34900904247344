.youtube-video {
  position: relative;
  width: 90vw;
  padding-bottom: 56.25%;
  padding-top: 30px;
  height: 0;
  overflow: hidden;
}
.youtube-video iframe,
.youtube-video object,
.youtube-video embed {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

@media (min-width: 768px) {
  .youtube-video {
    width: 80vw;
  }
}
