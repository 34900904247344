.top {
  display: flex;
  justify-content: flex-end;
  padding: .75rem;
  color: white;
  background-color: var(--color-primary, #073547);
}

.top__text {
  display: none;
}

.top__contact {
  display: inline-flex;
  align-items: center;
}
.top__contact-icon {
  margin-right: .5rem;
}
.top__contact-text {
  color: white !important;  /* mailto link hover */
  font-size: .8rem;
  font-weight: 700;
  line-height: 1;
  white-space: nowrap;
}

@media (min-width: 768px) {
  .top__text {
    margin-right: .75rem;
    display: inline;
    font-size: .9rem;
    white-space: nowrap;
  }
  .top__contact-text {
    font-size: .9rem;
  }
}
@media (min-width: 992px) {
  .top {
    padding: .75rem 1.5rem;
  }
  .top__text {
    font-size: 1rem;
  }
  .top__contact {
    margin-left: 1.5rem;
  }
  .top__contact-text {
    font-size: 1rem;
  }
}
