.image_wrapper {
  max-height: 12rem;
}

.image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}


@media (min-width: 768px) {
  .image_wrapper {
    max-height: 20rem;
  }
}


@media (min-width: 992px) {
  .image_wrapper {
    max-height: none;
  }
}
