.imprint {
  padding-top: 2rem;
  padding-bottom: 2rem;
}

.imprint > p {
  color: #2f3434;
  font-family: 'Open Sans', sans-serif;
}

.imprint > p > b {
  color: var(--color-primary, #073547);
}

.imprint__heading {
  margin-bottom: 1.5rem;
  color: var(--color-primary, #073547);
  font-size: 2rem;
  line-height: 1.2;
}

@media (min-width: 992px) {
  .imprint {
    padding-top: 4rem;
    padding-bottom: 4rem;
  }
  .imprint__heading {
    font-size: 2.75rem;
  }
  .imprint > p {
    margin-bottom: 1.5rem;
    font-size: 1.25rem;
  }
}
