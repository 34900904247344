.about-banner {
  position: relative;
}

.about-banner__image {
  width: 100%;
  height: 70vh;
  object-fit: cover;
}

.about-banner__overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: flex-end;
  padding-bottom: 2rem;
}

.about-banner__heading {
  max-width: 20rem;
  color: white;
  font-size: 2rem;
  font-weight: 500;
  line-height: 1.2;
}

@media (min-width: 992px) {
  .about-banner__overlay {
    padding-bottom: 3rem;
  }
  .about-banner__heading {
    max-width: 28rem;
    font-size: 2.75rem;
  }
}
