.network {
  padding-top: 2rem;
  padding-bottom: 4rem;
  background-color: var(--color-primary, #073547);
}

/* Text */
.network__text-block {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.network__heading {
  color: white;
  font-size: 2rem;
  font-weight: 500;
}
.network__paragraph {
  margin-bottom: 2rem;
  color: white;
  font-family: 'Open Sans', sans-serif;
}

/* Map */
.network-map {
  position: relative;
  margin: 0 auto 1.5rem;
}
.network-map__map-image {
  width: 100%;
}
.network-map__pin {
  position: absolute;
  width: 2vw;
}

@media (min-width: 576px) {
  .network-map__pin {
    width: .75rem;
  }
}
@media (min-width: 768px) {
  .network {
    padding-top: 3rem;
  }
  .network-map__pin {
    width: 1vw;
  }
}
@media (min-width: 992px) {
  .network {
    padding-bottom: 2rem;
  }
  .network__heading {
    font-size: 2.75rem;
  }
  .network__paragraph {
    font-size: 1.25rem;
  }
  .network-map__pin {
    width: .75rem;
  }
}
