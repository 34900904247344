.verify {
  text-align: center;
  padding-top: 2rem;
  padding-bottom: 2rem;
}

.verify__heading {
  margin-bottom: 1rem;
  color: var(--color-primary, #073547);
  font-size: 2rem;
  font-weight: 700;
  line-height: 1.2;
}
.verify__subheading {
  max-width: 24rem;
  margin: 0 auto 3rem;
  color: var(--color-primary, #073547);
}

.verify__input-wrapper {
  position: relative;
  max-width: 23.5rem;
  margin: 0 auto 4rem;
}
.verify__incorrect {
  position: absolute;
  left: 1.5rem;
  color: #c71e1e;
}
.verify__submit-wrapper {
  margin-bottom: 1.5rem;
}

.verify__hint {
  max-width: 28.5rem;
  margin: auto;
  color: var(--color-primary, #073547);
  font-size: .8rem;
}
.verify__text-accent {
  color: var(--color-secondary, #ffb332);
  font-weight: 700;
}


@media (min-width: 576px) {
  .verify {
    padding-top: 3rem;
    padding-bottom: 3rem;
  }
}

@media (min-width: 992px) {
  .verify {
    padding-top: 4rem;
    padding-bottom: 5rem;
  }
  .verify__heading {
    font-size: 2.75rem;
  }
  .verify__subheading {
    margin-bottom: 4.5rem;
  }
  .verify__input-wrapper {
    margin-bottom: 4.5rem;
  }
  .verify__submit-wrapper {
    margin-bottom: 2rem;
  }
}
