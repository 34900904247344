.video-modal .ant-modal-content {
  box-shadow: none;
  background-color: transparent;
}
.video-modal .ant-modal-header {
  border: none;
  background-color: transparent;
}
.video-modal .ant-modal-title {
  visibility: hidden;
}

/* OBSOLETE modal video dimensions responsive limits (backup) */
/*@media (min-width: 576px) {
  .video-modal {
    max-width: 560px;
  }
}
@media (min-width: 768px) {
  .video-modal {
    max-width: 720px;
  }
}
@media (min-width: 992px) {
  .video-modal {
    max-width: 960px;
  }
}*/
/*@media (min-width: 1200px) {
  .video-modal {
    max-width: 1140px;
  }
}*/
