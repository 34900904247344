.video {
  position: relative;
  padding-bottom: 3rem;
}
.video_dark {
  background-color: var(--color-primary, #073547);
}

.video__block-small {
  position: relative;
}
.video__block-large {
  display: none;
}
.video__image {
  width: 100%;
}

.video__text {
  margin-top: -30%;
  color: var(--color-primary, #073547);
}
.video__text .video__heading {
  color: var(--color-primary, #073547);
}
.video__text_inverse {
  color: white;
}
.video__text_inverse .video__heading {
  color: white;
}
.video__heading {
  margin-bottom: 1.5rem;
  font-size: 2rem;
  font-weight: 500;
}
.video__paragraph {
  margin-bottom: 1.5rem;
  font-family: 'Open Sans', sans-serif;
  font-size: 1rem;
}


@media (min-width: 768px) {
  .video {
    padding-bottom: 5rem;
  }
  .video__block-small {
    display: none;
  }
  .video__block-large {
    position: relative;
    display: block;
  }
  .video__text {
    margin-top: -25%;
  }
}

@media (min-width: 992px) {
  .video {
    padding-top: 4rem;
    padding-bottom: 5rem;
  }
  .video__block-large {
    position: absolute;
    top: 0;
    left: 0;
  }
  .video__image {
    width: 60%;
  }
  .video__text {
    margin-top: 0;
  }
  .video__heading {
    font-size: 2.75rem;
  }
}

@media (min-width: 1200px) {
  .video {
    padding-top: 7rem;
    padding-bottom: 7rem;
  }
  .video__image {
    width: 65%;
  }
  .video__paragraph {
    margin-bottom: 2rem;
    font-size: 1.25rem;
  }
}
