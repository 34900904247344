.valuation-header {
  background-image: url('./background.png');
  background-size: cover;
  background-position: center;
}

.valuation-header__container {
  height: 80vh;
}

/* disabling bootstrap `.container` spacing */
@media (max-width: 991.98px) {
  .valuation-header__container {
    max-width: 100%;  /* overriding the `.container` `max-width` property */
    margin: 0;
    padding-left: 0;
    padding-right: 0;
  }
}

@media (min-width: 992px) {
  .valuation-header__container {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
  }
}
