.separator_1, .separator_2 {
  width: 100%;
  height: 0;
  border-top: 1px solid #e6e6e6;
  margin: 1rem 0;
}

.price_blocks {
  display: inline-block;
}
.plot_section {
  margin-bottom: 2rem;
}
.counter_section {
  margin-bottom: 2rem;
}

.buttons_section {
  text-align: left;
}

@media (min-width: 768px) {
  .price_section {
    float: left;
    width: 35%;
  }
  .plot_section {
    float: right;
    width: 60%;
  }
  .counter_section {
    float: left;
    width: 35%;
  }
  .buttons_section {
    clear: both;
    text-align: right;
  }
  .separator_2 {
    margin: 2rem 0;
  }
}

@media (min-width: 992px) {
  .separator_1 {
    margin: 1.5rem 0;
  }
  .buttons_section {
    clear: none;
    text-align: left;
  }
}
