.machine-card {
  border-radius: 2px;
  box-shadow:
    0 2px 2px 0 rgba(0, 0, 0, 0.24),
    0 0 2px 0 rgba(0, 0, 0, 0.12);
  margin-bottom: 1.5rem;
  cursor: pointer;
}

.machine-card__media {
  position: relative;
  height: 0;
  padding-bottom: 64%;
  overflow: hidden;
  border-top-left-radius: 2px;
  border-top-right-radius: 2px;
}

.machine-card__image {
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}

.machine-card__like-button {
  position: absolute;
  right: .5rem;
  bottom: .5rem;
  border: none;
  padding: .5rem;
  background: none;
  line-height: 1;
}

.machine-card__like-icon {
  color: var(--color-secondary, #ffb332);
}

.machine-card__description {
  display: flex;
  padding: 1rem .75rem .75rem;
  background-color: white;
}
.machine-card__left {
  width: 50%;
}
.machine-card__right {
  text-align: center;
  width: 50%;
}
.machine-card__title {
  color: var(--color-primary, #073547);
  font-weight: 700;
  text-transform: uppercase;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.machine-card__location {
  color: #999999;
  font-size: .7rem;
}
.machine-card__price {
  color: var(--color-secondary, #ffb332);
  font-weight: 700;
  white-space: nowrap;
}
.machine-card__forecast {
  color: var(--color-primary, #073547);
  font-size: .7rem;
  font-weight: 700;
}