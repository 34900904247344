/* overriding default Ant styles */
.ant-drawer-body {
  height: 100%;
}
.ant-drawer-content {
  background-color: var(--color-primary, #073547);
  color: white;
}


/* custom styles */
.custom-drawer__content {
  display: flex;
  flex-direction: column;
  text-align: right;
  height: 100%;
}

.custom-drawer__separator {
  border-top: 1px solid rgba(151, 151, 151, 0.48);
}

.custom-drawer__close-button {
  border: none;
  background: none;
}

.custom-drawer__logo-link {
  display: inline-block;
  width: 12rem;
  padding: 1.5rem 0;
}

.custom-drawer__logo-image {
  width: 100%;
  pointer-events: none;
}
