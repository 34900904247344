.contact {
  background-size: cover;
  background-position: center;
}

.contact__container {
  text-align: center;
  padding-top: 3rem;
  padding-bottom: 3rem;
}

.contact__heading {
  margin-bottom: 2rem;
  color: var(--color-primary, #073547);
  font-size: 2rem;
  font-weight: 500;
  line-height: 1.2;
}

.contact__link {
  color: var(--color-secondary, #ffb332) !important;
  font-weight: 500;
}

@media (min-width: 768px) {
  .contact__container {
    padding-top: 4rem;
    padding-bottom: 4rem;
  }
}

@media (min-width: 992px) {
  .contact {
    padding-top: 3rem;
    padding-bottom: 3rem;
  }
  .contact__container {
    max-width: 960px !important;
    border-radius: 6px;
    box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.25);
    padding-bottom: 3rem;
    background-color: white;
  }
  .contact__heading {
    margin-bottom: 3rem;
    font-size: 2.75rem;
  }
}
