/* The buttons styles have been copied from the Handler1 global styles (initially). */

/* buttons */
.dshb-button {
  display: inline-block;
  border: 1px solid var(--dshb-color-secondary, #ffb332);
  border-radius: 2.5rem;
  padding: .75rem 1.5rem;
  background-color: var(--dshb-color-secondary, #ffb332);
  color: white !important; /* link hover */
  font-size: .9rem;
  font-weight: 700;
  text-decoration: none !important; /* link hover */
}

.dshb-button_outline {
  background-color: transparent;
  color: var(--dshb-color-secondary, #ffb332) !important; /* link hover */
}

.dshb-button_round {
  display: inline-flex;
  align-items: center;
  justify-content: center;  
  width: 2.5rem;
  height: 2.5rem;
  padding: 0;
}
