.marketplace__container {
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
}

@media (min-width: 992px) {
  .marketplace__container {
    padding: 2.5rem;
    background-color: #f6f6f6;
  }
}
