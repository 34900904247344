.navbar {
  position: -webkit-sticky;
  position: sticky;
  z-index: 1000;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: .75rem .5rem .75rem 1rem;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.24);
  background-color: white;
}

/* brand (logo) */
.navbar__brand {
  display: block;
  width: 11rem;
  padding: .25rem 0;
}

/* mobile menu button */
.navbar__menu-button {
  padding: .5rem .5rem 0rem;
  border: none;
  background: none;
  line-height: 1;
}
.navbar__menu-icon {
  color: var(--color-primary, #073547);
  font-size: 1.5rem;
}

/* desktop expandable section */
.navbar__expand {
  display: none;
}
.navbar__signup-button {
  display: none;
}
.navbar__signup-button-expand {
  display: none;
}

@media (min-width: 768px) {
  .navbar {
    padding: 1rem 1rem 1.25rem 2rem;
  }
}
@media (min-width: 992px) {
  .navbar {
    padding-right: 2rem;
  }
  .navbar__brand {
    width: 14.5rem;
  }
  .navbar__menu-button {
    display: none;
  }
  .navbar__expand {
    display: block;
  }
}
@media (min-width: 1200px) {
  .navbar__signup-button {
    display: inline-block;
    margin-left: 1.5rem;
  }
}
@media (min-width: 1350px) {
  .navbar__signup-button-expand {
    display: inline;
  }
}
