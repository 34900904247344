/* EMAIL TEMPLATE TYPOGRAPHY */
.email-tmpl__article h2 {
  margin-bottom: 1.25rem;
  color: var(--color-primary, #3b4143);
  font-size: 2rem;
  line-height: 1.2;
}
.email-tmpl__article p {
  text-align: justify;
  color: var(--color-primary, #3b4143);
  font-family: 'Open Sans', sans-serif;
  font-size: 1rem;
}
.email-tmpl__article a {
  color: var(--color-secondary, #ffb332) !important;  /* hover */
  font-weight: 700;
  text-decoration: underline;
  word-wrap: break-word;
}

.email-tmpl__heading {
  margin-bottom: 1.25rem;
  color: var(--color-primary, #3b4143);
  font-size: 2rem;
  line-height: 1.2;
}
.email-tmpl__paragraph {
  text-align: justify;
  color: var(--color-primary, #3b4143);
  font-family: 'Open Sans', sans-serif;
}
.email-tmpl__link {
  color: var(--color-secondary, #ffb332) !important;  /* hover */
  font-weight: 700;
  text-decoration: underline;
  word-wrap: break-word;
}


/* EMAIL TEMPLATE GENERAL STYLES */
.email-tmpl__separator {
  border-top: 1px solid #eaeaea;
}


/* EMAIL TEMPLATE SPECIAL STYLES */
.email-tmpl__offer-wrapper {
  max-width: 20rem;
  margin: 0 auto 1rem;
}

.email-tmpl__banner-heading {
  color: white;
  font-size: 1.5rem;
  line-height: 1.2;
}
.email-tmpl__banner-text {
  color: white;
  line-height: 1.2;
}

@media (min-width: 768px) {
  .email-tmpl__banner-heading {
    font-size: 2rem;
  }
}
