.marketplace-panel {
  padding: .5rem;
}

.marketplace-panel__separator {
  border-top: 1px solid #efefef;
}

.marketplace-panel__collapse-header {
  padding: .25rem 0;
  color: #64686a;
  font-weight: 500;
}

@media (min-width: 992px) {
  .marketplace-panel {
    border-radius: 6px;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.18);
    padding: 1.5rem 1.25rem;
    background-color: white;
  }
}
