.laptop {
  padding: 2.5rem 0;
  overflow-x: hidden;
  background-color: var(--color-primary, #073547);
}

.laptop__heading {
  margin-bottom: 1rem;
  color: white;
  font-size: 2rem;
  font-weight: 500;
  line-height: 1.2;
}

.laptop__text {
  margin-bottom: 2rem;
  padding-right: 1rem;
  color: white;
  font-family: 'Open Sans', sans-serif;
  line-height: 1.7;
}

.laptop__show-link {
  display: inline-flex;
}
.laptop__show-link-text {
  margin-left: .5rem;
  color: var(--color-accent, #ffb332);
  font-size: 1.1rem;
  font-weight: 700;
  text-decoration: underline;
}

.laptop__image-wrapper {
  text-align: right;
  margin-bottom: 2rem;
}
.laptop__image {
  width: 90%;
}

@media (min-width: 576px) {
  .laptop__image-wrapper {
    position: absolute;
    top: 0;
    left: 68%;
    display: flex;
    align-items: center;
    height: 100%;
  }
  .laptop__image {
    width: auto;
    height: 72vw;
    max-height: 100%;
    margin-top: 1rem;
    margin-bottom: -.5rem;
  }
  .laptop__text {
    padding-right: 4rem;
  }
}
@media (min-width: 768px) {
  .laptop {
    padding: 5rem 0;
  }
  .laptop__image-wrapper {
    left: 60%;
  }
}
@media (min-width: 992px) {
  .laptop {
    padding: 5rem 0;
  }
  .laptop__image-wrapper {
    left: 55%;
  }
  .laptop__heading {
    font-size: 2.75rem;
  }
  
}
@media (min-width: 1200px) {
  .laptop {
    padding: 7rem 0;
  }
  .laptop__image-wrapper {
    left: auto;
    right: 0;
  }
  .laptop__text {
    font-size: 1.25rem;
  }
}
