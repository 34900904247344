/*
  CSS Utilities.
  
  The class naming imitates Bootstrap.
  (Meant to be compatible with Bootstrap, to switch to a Bootstrap theme in future).
*/


.text-primary {
  color: var(--color-primary, #3b4143) !important;
}
