/* 
  FILE CONTENTS COPIED FROM "../terms-of-service/terms-of-service.css"
*/

.privacy-policy {
  padding-top: 2rem;
  padding-bottom: 2rem;
}

.privacy-policy__separator {
  margin-bottom: 2rem;
}

@media (min-width: 992px) {
  .privacy-policy {
    max-width: 960px !important;  /* overriding bootstrap container */
    padding-top: 4rem;
    padding-bottom: 4rem;
  }
  .privacy-policy__separator {
    margin-bottom: 3rem;
  }
}
