.hdlr1-article > h1,
.hdlr1-article > h2 {
  color: var(--color-primary, #073547);
}

.hdlr1-article > h1 {
  margin-bottom: 1.5rem;
  font-size: 2rem;
  line-height: 1.2;
}
.hdlr1-article > h2 {
  margin-bottom: 1.25rem;
  font-size: 1.5rem;
}
.hdlr1-article > h3 {
  margin-bottom: 1.25rem;
  color: #232323;
  font-size: 1.15rem;
}

.hdlr1-article > p,
.hdlr1-article > ul,
.hdlr1-article > ol {
  text-align: justify;
  color: #2f3434;
  font-family: 'Open Sans', sans-serif;
  font-size: 1rem;
}

.hdlr1-article a {
  color: var(--color-secondary, #ffb332);
  font-weight: 700;
  text-decoration: underline;
  word-wrap: break-word;
}

@media (min-width: 992px) {
  .hdlr1-article > h1 {
    font-size: 2.75rem;
  }
  .hdlr1-article > h2 {
    font-size: 2rem;
  }
  .hdlr1-article > h3 {
    font-size: 1.5rem;
  }
  .hdlr1-article > p,
  .hdlr1-article > ul,
  .hdlr1-article > ol {
    margin-bottom: 1.5rem;
    font-size: 1.25rem;
  }
}
