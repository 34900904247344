.footer {
  padding-top: 3rem;
  background-color: var(--color-primary, #073547);
  color: white;
}

.footer__text {
  display: block;
  font-size: 1.25rem;
  line-height: 1.3;
}
.footer__text_light {
  font-weight: 300;
}
.footer__text_medium {
  font-weight: 500;
}

.footer__separator {
  border-top: 1px solid rgba(151, 151, 151, 0.46);
  margin-bottom: 1.5rem;
}

.footer__heading {
  margin-bottom: 1.5rem;
  color: var(--color-accent, #ffb332);
  font-weight: 700;
}

.footer__link-wrapper {
  margin-bottom: 1rem;
}
.footer__link {
  color: white !important;
  font-weight: 500;
  text-decoration: underline;
}


/* CODE COPIED FROM THE TOP COMPONENT */
.footer__contact {
  display: flex;
  align-items: flex-start;
  margin-bottom: 1.5rem;
}
.footer__contact-icon-wrapper {
  text-align: center;
  width: 1.5rem;
  margin-right: .75rem;
}
.footer__contact-text {
  color: white !important;  /* mailto link */
  font-weight: 700;
}
/* END COPIED CODE */


@media (min-width: 768px) {
  .footer__text {
    display: inline;
  }
  .footer__separator {
    border-top-color: transparent;
  }
}
@media (min-width: 992px) {
  .footer {
    padding-top: 3.5rem;
  }
}
