.banner {
  display: flex;
  align-items: flex-end;
  height: 35vh;
  padding-bottom: 1rem;
  background-image: url('./background.jpg');
  background-size: cover;
  background-position: center;
}


@media (min-width: 768px) {
  .banner {
    background-position: center 35%;
    padding-bottom: 2rem;
  }
}

@media (min-width: 992px) {
  .banner {
    height: 40vh;
  }
}
