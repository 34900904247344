.thank-you {
  text-align: center;
}

.thank-you__container {
  padding: 2rem 2rem 1rem;
}

.thank-you__image-block {
  margin-bottom: 1.5rem;
}

.thank-you__heading {
  color: var(--color-primary, #073547);
  font-size: 1.75rem;
}
.thank-you__paragraph {
  color: var(--color-primary, #073547);
  font-family: 'Open Sans', sans-serif;
  line-height: 1.3;
}

@media (min-width: 576px) {
  .thank-you__container {
    display: inline-flex;
    align-items: center;
    padding: 1rem 4rem 1rem 3rem;
  }

  .thank-you__image-block {
    margin-bottom: 0;
  }

  .thank-you__text-block {
    text-align: left;
    margin-left: 2rem;
  }
  .thank-you__heading {
    font-size: 2.5rem;
  }
}
