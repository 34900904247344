.offers {
  padding-top: 2rem;
  padding-bottom: 3rem;
}

.offers__heading {
  margin-bottom: 1rem;
  color: var(--color-primary, #073547);
  font-size: 2rem;
  font-weight: 500;
  line-height: 1.2;
}
.offers__subheading {
  color: #2F3434;
  font-family: 'Open Sans', sans-serif;
}

/* carousel */
.offers__carousel-container {
  display: flex;
  margin-bottom: 2.5rem;
  padding: 0;
}
.offers__carousel-button {
  width: 20%;
  height: auto;
  border: none;
  background: none;
}
.offers__carousel {
  width: 60%;
} 

@media (min-width: 576px) {
  .offers__carousel-container {
    margin-bottom: 3rem;
  }
  .offers__carousel-button {
    width: 12.5%;
  }
  .offers__carousel {
    width: 75%;
  }
}

@media (min-width: 768px) {
  .offers {
    padding-top: 3rem;
  }
  .offers__carousel-button {
    width: 10%;
  }
  .offers__carousel {
    width: 80%;
  }
}

@media (min-width: 992px) {
  .offers {
    padding-top: 4rem;
    padding-bottom: 5rem;
  }
  .offers__heading {
    font-size: 2.75rem;
  }
  .offers__subheading {
    font-size: 1.25rem;
  }

  .offers__carousel-container {
    margin-bottom: 3rem;
  }
  .offers__carousel-button {
    width: 5%;
  }
  .offers__carousel {
    width: 90%;
  }
}

@media (min-width: 1200px) {
  .offers__carousel-container {
    margin-bottom: 4rem;
  }
}
