.menu {
  display: flex;
  list-style: none;
  margin: 0;
  padding: 0;
}
.menu > li {
  margin-left: 1.75rem;
}

.link {
  color: var(--color-secondary, #ffb332) !important;  /* hover */
  font-weight: 500;
  text-decoration: underline;
}
