/* General & Defaults */
.benefit {
  max-width: 18rem;
  margin: 0 auto;
}
.benefit__image-block {
  text-align: center;
  width: 7.5rem;
  margin: 0 auto 1rem;
}
.benefit__text-block {
  text-align: center;
  line-height: 1.3;
}

/* Text & Theme */
.benefit_dark > .benefit__text-block > .benefit__text {
  color: var(--color-primary, #073547);
  font-weight: 500;
}
.benefit_light > .benefit__text-block > .benefit__text {
  color: white;
}
.benefit__text_accent {
  color: var(--color-secondary, #ffb332) !important;
  font-weight: 500;
}

/* Dense variant */
.benefit_dense {
  display: flex;
  align-items: center;
  max-width: 22rem;
}
.benefit_dense > .benefit__image-block {
  margin-bottom: 0;
}
.benefit_dense > .benefit__text-block {
  flex: 1;
  text-align: left;
}

@media (min-width: 576px) {
  .benefit {
    display: flex;
    align-items: center;
    max-width: 22rem;
  }
  .benefit__image-block {
    margin-bottom: 0;
  }
  .benefit__text-block {
    flex: 1;
    text-align: left;
  }
}

@media (min-width: 1200px) {
  .benefit__text {
    font-size: 1.25rem;
  }
}
