.video__screen {
  position: absolute;
  /*border: 1px solid aquamarine; */              /* for dev purposes... */
  /*background-color: rgba(127, 255, 212, 0.3);*/ /* ...and can be safely removed */
  background-image: url('./video-screen.png');
  background-size: cover;
  background-position: center;
  cursor: pointer;
}

.video__screen_small {
  top: 14%;
  left: 8%;
  width: 83%;
  height: 49%;
}

.video__screen_large {
  top: 26%;
  left: 8%;
  right: 90%;
  width: 65.5%;
  height: 42%;
}

@media (min-width: 992px) {
  .video__screen_large {
    top: 26%;
    left: 5%;
    right: 90%;
    width: 39%;
    height: 42%;
  }
}

@media (min-width: 1200px) {
  .video__screen_large {
    top: 26%;
    left: 5.5%;
    right: 90%;
    width: 42.1%;
    height: 42%;
  }
}
