.benefit-grid {
  padding-top: 2rem;
  padding-bottom: 2rem;
}

.benefit-grid__heading {
  margin-bottom: 3rem;
  color: var(--color-primary, #073547);
  font-size: 2rem;
  font-weight: 500;
  line-height: 1.2;
}

.benefit-grid__item {
  margin-bottom: 3rem;
}


@media (min-width: 576px) {
  .benefit-grid {
    text-align: center;
    padding-top: 3rem;
  }
  .benefit-grid__heading {
    margin-bottom: 4rem;
  }
}

@media (min-width: 768px) {
  .benefit-grid {
    padding-top: 5rem;
    padding-bottom: 1rem;
  }
  .benefit-grid__heading {
    margin-bottom: 5rem;
  }
  .benefit-grid__item {
    margin-bottom: 4rem;
  }
}

@media (min-width: 992px) {
  .benefit-grid {
    padding-top: 7rem;
    padding-bottom: 4rem;
  }
  .benefit-grid__heading {
    margin-bottom: 5rem;
    font-size: 2.75rem;
  }
  .benefit-grid__item {
    margin-bottom: 5rem;
  }
}
