.sign-up-page {
  display: flex;
  align-items: center;
  text-align: center;
  min-height: 70vh;
  padding-top: 1rem;
  padding-bottom: 2rem;
}

.sign-up-page__container {
  display: inline-block;
}

@media (min-width: 768px) {
  .sign-up-page__container {
    padding: 1rem 0;
  }
}
@media (min-width: 992px) {
  .sign-up-page {
    padding-top: 3rem;
    padding-bottom: 4rem;
  }
  .sign-up-page__container {
    box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.25);
    border-radius: 6px;
    padding: 1rem;
  }
}
