.testimonials {
  position: relative;
  background-color: #f2f2f2;
}
.testimonials__top-overlay {
  position: absolute;
  top: 0;
  width: 100%;
  height: 4.5rem;
  background-color: var(--color-primary, #073547);
}

.testimonial {
  display: flex;
  flex-direction: column;
  margin-bottom: 3rem;
}

.testimonial__photo {
  margin: 0 auto;
  width: 140px;
  height: 140px;
  border-radius: 5px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
  margin-bottom: .95rem;
}
.testimonial__photo-image {
  object-fit: cover;
  width: 100%;
  height: 100%;
  border-radius: 5px;
}

.testimonial__text {
  flex: 1;
  text-align: center;
  padding: 1.5rem;
}
.testimonial__name {
  margin-bottom: 1rem;
  color: var(--color-primary, #073547);
  font-size: 1.5rem;
  font-weight: 700;
  text-transform: uppercase;
}
.testimonial__feedback {
  color: var(--color-primary, #073547);
  line-height: 1.7;
}

@media (min-width: 992px) {
  .testimonials {
    padding-bottom: 1rem;
  }
  .testimonial__text {
    border-radius: 5px;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
    background-color: white;
  }
}
