@import url(https://fonts.googleapis.com/css?family=Ubuntu:300,400,500,700&display=swap);
@import url(https://fonts.googleapis.com/css?family=Open+Sans&display=swap);
.hdlr1-article > h1,
.hdlr1-article > h2 {
  color: #073547;
  color: var(--color-primary, #073547);
}

.hdlr1-article > h1 {
  margin-bottom: 1.5rem;
  font-size: 2rem;
  line-height: 1.2;
}
.hdlr1-article > h2 {
  margin-bottom: 1.25rem;
  font-size: 1.5rem;
}
.hdlr1-article > h3 {
  margin-bottom: 1.25rem;
  color: #232323;
  font-size: 1.15rem;
}

.hdlr1-article > p,
.hdlr1-article > ul,
.hdlr1-article > ol {
  text-align: justify;
  color: #2f3434;
  font-family: 'Open Sans', sans-serif;
  font-size: 1rem;
}

.hdlr1-article a {
  color: #ffb332;
  color: var(--color-secondary, #ffb332);
  font-weight: 700;
  text-decoration: underline;
  word-wrap: break-word;
}

@media (min-width: 992px) {
  .hdlr1-article > h1 {
    font-size: 2.75rem;
  }
  .hdlr1-article > h2 {
    font-size: 2rem;
  }
  .hdlr1-article > h3 {
    font-size: 1.5rem;
  }
  .hdlr1-article > p,
  .hdlr1-article > ul,
  .hdlr1-article > ol {
    margin-bottom: 1.5rem;
    font-size: 1.25rem;
  }
}

/* buttons */
.hdlr1-button {
  display: inline-block;
  border: none;
  border-radius: 2.5rem;
  padding: .75rem 1.5rem;
  background-color: #ffb332;
  background-color: var(--color-secondary, #ffb332);
  color: white !important; /* link hover */
  font-size: .9rem;
  font-weight: 700;
  text-transform: uppercase;
  text-decoration: none !important; /* link hover */
  letter-spacing: 1.3px;
}

.hdlr1-button_outline {
  border: 1px solid #ffb332;
  border: 1px solid var(--color-secondary, #ffb332);
  background-color: transparent;
  color: #ffb332 !important;
  color: var(--color-secondary, #ffb332) !important; /* link hover */
}

/* forms */
.hdlr1-form__group {
  display: flex; 
  margin-bottom: .75rem;
}
.hdlr1-form__group::after {
  content: '';
  display: inline-block;
  text-align: right;
  width: 1rem;
  padding-top: .9rem;
}
.hdlr1-form__group_required::after {
  content: '*';
  color: #ffb332;
  color: var(--color-secondary, #ffb332);
  font-size: .9rem;
  font-weight: 700;
}
.hdlr1-form__group_full-size::after {
  width: 0;
}


.hdlr1-form__control {
  position: relative;
  display: flex;
  flex: 1 1;
  border-radius: 3px;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.36);
  padding: 0 0rem 0 1.25rem;
  background-color: white;
}

/* filled variant */
.hdlr1-form__control_filled {
  border-bottom: 1px solid rgba(0, 0, 0, 0.26);
  border-radius: 0;
  box-shadow: none;
}
.hdlr1-form__control_filled:hover {
  border-bottom: 1px solid rgba(0, 0, 0, 0.36);
}
/* NOTE: focus styling is currently toggled via JavaScript */
.hdlr1-form__control_filled.hdlr1-form__control_filled_focus {
  border-bottom:
    1px solid #073547
    !important;
  border-bottom:
    1px solid var(--color-primary, #073547)
    !important;   /* to override :hover */
}


.hdlr1-form__control-input {
  flex: 1 1;
  width: 100%;
  border: none;
  padding: .75rem .5rem .75rem 0;
  background: none;
  outline: none;
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
}
.hdlr1-form__control-input::-webkit-input-placeholder {
  /*color: #3b4143;*/
  font-style: italic;
}
.hdlr1-form__control-input:-ms-input-placeholder {
  /*color: #3b4143;*/
  font-style: italic;
}
.hdlr1-form__control-input::-ms-input-placeholder {
  /*color: #3b4143;*/
  font-style: italic;
}
.hdlr1-form__control-input::placeholder {
  /*color: #3b4143;*/
  font-style: italic;
}


.hdlr1-form__control-prefix {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 1.5rem;
  height: 100%;
  margin-right: 1rem;
  color: #073547;
  color: var(--color-primary, #073547);
  font-size: 1.25rem;
}

.hdlr1-form__select-icon {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: .75rem;
  width: 10px;
  height: 6px;
}

/*
  CSS Utilities.
  
  The class naming imitates Bootstrap.
  (Meant to be compatible with Bootstrap, to switch to a Bootstrap theme in future).
*/


.text-primary {
  color: #3b4143 !important;
  color: var(--color-primary, #3b4143) !important;
}

@font-face {
  font-family: 'fontello';
  src: url(/static/media/fontello.fd62851f.eot);
  src: url(/static/media/fontello.fd62851f.eot#iefix) format('embedded-opentype'),
       url(/static/media/fontello.2792b693.woff2) format('woff2'),
       url(/static/media/fontello.c232c214.woff) format('woff'),
       url(/static/media/fontello.f950c8ed.ttf) format('truetype'),
       url(/static/media/fontello.7ba6a057.svg#fontello) format('svg');
  font-weight: normal;
  font-style: normal;
}
/* Chrome hack: SVG is rendered more smooth in Windozze. 100% magic, uncomment if you need it. */
/* Note, that will break hinting! In other OS-es font will be not as sharp as it could be */
/*
@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: 'fontello';
    src: url('../font/fontello.svg?33876888#fontello') format('svg');
  }
}
*/
 
 [class^="icon-"]:before, [class*=" icon-"]:before {
  font-family: "fontello";
  font-style: normal;
  font-weight: normal;
  speak: none;
 
  display: inline-block;
  text-decoration: inherit;
  width: 1em;
  margin-right: .2em;
  text-align: center;
  /* opacity: .8; */
 
  /* For safety - reset parent styles, that can break glyph codes*/
  font-feature-settings: normal;
  font-variant: normal;
  text-transform: none;
 
  /* fix buttons height, for twitter bootstrap */
  line-height: 1em;
 
  /* Animation center compensation - margins should be symmetric */
  /* remove if not needed */
  margin-left: .2em;
 
  /* you can be more comfortable with increased icons size */
  /* font-size: 120%; */
 
  /* Font smoothing. That was taken from TWBS */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
 
  /* Uncomment for 3D effect */
  /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */
}
 
.icon-phone:before { content: '\e800'; } /* '' */
.icon-chevron-down:before { content: '\e801'; } /* '' */
.icon-close:before { content: '\e802'; } /* '' */
.icon-location:before { content: '\e803'; } /* '' */
.icon-envelope:before { content: '\e804'; } /* '' */
.icon-globe:before { content: '\e805'; } /* '' */
.icon-menu:before { content: '\e806'; } /* '' */
.icon-padlock:before { content: '\e807'; } /* '' */
.icon-search:before { content: '\e808'; } /* '' */
.icon-company:before { content: '\e80a'; } /* '' */
.icon-user:before { content: '\e80b'; } /* '' */
.icon-login:before { content: '\e80c'; } /* '' */
.icon-instagram:before { content: '\e80d'; } /* '' */
.icon-facebook:before { content: '\e80e'; } /* '' */
/* Ubuntu font */
/* Open Sans font */

/* Global element styles */

/* Custom icon CSS font (generated at fontello.com) */


:root {
  --color-primary: #3b4143;
  --color-accent: #ffb332;  /* deprecated naming, use "color-secondary" instead */
  --color-secondary: #ffb332;
}

body {
  margin: 0;
  font-family: 'Ubuntu', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


/* Ant Slider global styles override */
.ant-slider-track {
  height: 3px;
  margin-top: 2px;
  background-color:
    #ffb332
    !important;
  background-color:
    var(--color-secondary, #ffb332)
    !important; /* hover */
}
.ant-slider-rail {
  height: 3px;
  margin-top: 2px;
  background-color: #dfdfdf;
}
.ant-slider-handle {
  width: 16px;
  height: 16px;
  border:
    1px solid rgba(0, 0, 0, 0.15)
    !important; /* hover, focus */
  box-shadow:
    0 2px 2px 0 rgba(0, 0, 0, 0.05),
    0 0 15px 0 rgba(0, 0, 0, 0.05)
    !important;  /* focus */
}
.ant-slider-disabled .ant-slider-handle {
  border-color: rgba(0, 0, 0, 0.15)
    !important; /* defaults hard override */
}

.banner {
  padding: 2.5rem 0;
  overflow-x: hidden;
  background-size: cover;
  background-position: center;
}

.banner__heading {
  margin-bottom: 1rem;
  color: #073547;
  color: var(--color-primary, #073547);
  font-size: 2rem;
  font-weight: 500;
  line-height: 1.2;
}

.banner__text {
  margin-bottom: 2rem;
  padding-right: 1rem;
  color: #2f3434;
  font-family: 'Open Sans', sans-serif;
  line-height: 1.7;
}

.banner__show-link {
  display: inline-flex;
}
.banner__show-link-text {
  margin-left: .5rem;
  color: #ffb332;
  color: var(--color-accent, #ffb332);
  font-size: 1.1rem;
  font-weight: 700;
  text-decoration: underline;
}

.banner__image-wrapper {
  text-align: right;
  margin-bottom: 2rem;
}
.banner__image {
  width: 90%;
}
.banner__image_xl {
  display: none;
}

@media (min-width: 576px) {
  .banner__image-wrapper {
    position: absolute;
    top: 0;
    left: 68%;
    display: flex;
    align-items: center;
    height: 100%;
  }
  .banner__image {
    width: auto;
    height: 72vw;
    max-height: 100%;
    margin-top: 1rem;
    margin-bottom: -.5rem;
  }
  .banner__text {
    padding-right: 4rem;
  }
}
@media (min-width: 768px) {
  .banner {
    padding: 3rem 0 3.5rem;
  }
  .banner__image-wrapper {
    left: 60%;
  }
}
@media (min-width: 992px) {
  .banner {
    padding: 5rem 0;
  }
  .banner__image-wrapper {
    left: 50%;
  }
  .banner__heading {
    font-size: 2.75rem;
  }
  .banner__text {
    font-size: 1.25rem;
  }
}
@media (min-width: 1200px) {
  .banner__image-wrapper {
    left: 50%;
    right: 0;
    justify-content: flex-end;
  }
}
@media (min-width: 1600px) {
  .banner__image-wrapper {
    justify-content: flex-start;
  }
  .banner__image {
    display: none;
  }
  .banner__image_xl {
    display: block;
    max-width: 90%;
    max-height: 100%;
  }
}

.benefit-line {
  padding: 1rem 0;
  box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.5) inset;
  background-color: #073547;
  background-color: var(--color-primary, #073547);
}
.benefit-line_light {
  box-shadow: none;
  background-color: transparent;
}

.benefit-line__separator {
  width: 2.5rem;
  border-top: 1px solid rgba(255, 255, 255, 0.25);
  margin: 0 auto;
}
.benefit-line_light .benefit-line__separator {
  border-color: #eaeaea;
}

.benefit-line__item {
  margin-top: 2rem !important;
  margin-bottom: 2rem !important;
}

@media (min-width: 992px) {
  .benefit-line__container {
    display: flex;
    align-items: center;
    padding: 0;
  }
  .benefit-line__separator {
    width: 0;
    height: 2.5rem;
    border-top: none;
    border-right: 1px solid rgba(255, 255, 255, 0.25);
    margin: 0;
  }
}

/* General & Defaults */
.benefit {
  max-width: 18rem;
  margin: 0 auto;
}
.benefit__image-block {
  text-align: center;
  width: 7.5rem;
  margin: 0 auto 1rem;
}
.benefit__text-block {
  text-align: center;
  line-height: 1.3;
}

/* Text & Theme */
.benefit_dark > .benefit__text-block > .benefit__text {
  color: #073547;
  color: var(--color-primary, #073547);
  font-weight: 500;
}
.benefit_light > .benefit__text-block > .benefit__text {
  color: white;
}
.benefit__text_accent {
  color: #ffb332 !important;
  color: var(--color-secondary, #ffb332) !important;
  font-weight: 500;
}

/* Dense variant */
.benefit_dense {
  display: flex;
  align-items: center;
  max-width: 22rem;
}
.benefit_dense > .benefit__image-block {
  margin-bottom: 0;
}
.benefit_dense > .benefit__text-block {
  flex: 1 1;
  text-align: left;
}

@media (min-width: 576px) {
  .benefit {
    display: flex;
    align-items: center;
    max-width: 22rem;
  }
  .benefit__image-block {
    margin-bottom: 0;
  }
  .benefit__text-block {
    flex: 1 1;
    text-align: left;
  }
}

@media (min-width: 1200px) {
  .benefit__text {
    font-size: 1.25rem;
  }
}

.offers {
  padding-top: 2rem;
  padding-bottom: 3rem;
}

.offers__heading {
  margin-bottom: 1rem;
  color: #073547;
  color: var(--color-primary, #073547);
  font-size: 2rem;
  font-weight: 500;
  line-height: 1.2;
}
.offers__subheading {
  color: #2F3434;
  font-family: 'Open Sans', sans-serif;
}

/* carousel */
.offers__carousel-container {
  display: flex;
  margin-bottom: 2.5rem;
  padding: 0;
}
.offers__carousel-button {
  width: 20%;
  height: auto;
  border: none;
  background: none;
}
.offers__carousel {
  width: 60%;
} 

@media (min-width: 576px) {
  .offers__carousel-container {
    margin-bottom: 3rem;
  }
  .offers__carousel-button {
    width: 12.5%;
  }
  .offers__carousel {
    width: 75%;
  }
}

@media (min-width: 768px) {
  .offers {
    padding-top: 3rem;
  }
  .offers__carousel-button {
    width: 10%;
  }
  .offers__carousel {
    width: 80%;
  }
}

@media (min-width: 992px) {
  .offers {
    padding-top: 4rem;
    padding-bottom: 5rem;
  }
  .offers__heading {
    font-size: 2.75rem;
  }
  .offers__subheading {
    font-size: 1.25rem;
  }

  .offers__carousel-container {
    margin-bottom: 3rem;
  }
  .offers__carousel-button {
    width: 5%;
  }
  .offers__carousel {
    width: 90%;
  }
}

@media (min-width: 1200px) {
  .offers__carousel-container {
    margin-bottom: 4rem;
  }
}

/* offer */
.offer__image-wrapper {
  position: relative;
  height: 0;
  margin-bottom: 1rem;
  padding-bottom: 100%;
}
.offer__image {
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}

.offer__title {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 2.5rem;
  color: #073547;
  color: var(--color-primary, #073547);
  font-size: 1.35rem;
  font-weight: 700;
  text-transform: uppercase;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.offer__description {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
  text-align: center;
  height: 2.5rem;
  margin-bottom: .75rem;
  color: #073547;
  color: var(--color-primary, #073547);
}

.offer__price-range {
  display: flex;
  align-items: center;
  color: #e6e6e6;
  font-size: 1.35rem;
  font-weight: 500;
}
.offer__price_muted {
  display: inline-block;
  width: 6rem;
  height: 1.5rem;
  border-radius: 1rem;
  margin: 0 .5rem;
  background-color: #e6e6e6;
}

.offer {
  padding: 0 .25rem;
}
@media (min-width: 576px) {
  .offer {
    padding: 0 .5rem;
  }
  .offer__title {
    justify-content: flex-start;
  }
  .offer__description {
    text-align: left;
  }
}
@media (min-width: 992px) {
  .offer {
    padding: 0 .75rem;
  }
}

.video {
  position: relative;
  padding-bottom: 3rem;
}
.video_dark {
  background-color: #073547;
  background-color: var(--color-primary, #073547);
}

.video__block-small {
  position: relative;
}
.video__block-large {
  display: none;
}
.video__image {
  width: 100%;
}

.video__text {
  margin-top: -30%;
  color: #073547;
  color: var(--color-primary, #073547);
}
.video__text .video__heading {
  color: #073547;
  color: var(--color-primary, #073547);
}
.video__text_inverse {
  color: white;
}
.video__text_inverse .video__heading {
  color: white;
}
.video__heading {
  margin-bottom: 1.5rem;
  font-size: 2rem;
  font-weight: 500;
}
.video__paragraph {
  margin-bottom: 1.5rem;
  font-family: 'Open Sans', sans-serif;
  font-size: 1rem;
}


@media (min-width: 768px) {
  .video {
    padding-bottom: 5rem;
  }
  .video__block-small {
    display: none;
  }
  .video__block-large {
    position: relative;
    display: block;
  }
  .video__text {
    margin-top: -25%;
  }
}

@media (min-width: 992px) {
  .video {
    padding-top: 4rem;
    padding-bottom: 5rem;
  }
  .video__block-large {
    position: absolute;
    top: 0;
    left: 0;
  }
  .video__image {
    width: 60%;
  }
  .video__text {
    margin-top: 0;
  }
  .video__heading {
    font-size: 2.75rem;
  }
}

@media (min-width: 1200px) {
  .video {
    padding-top: 7rem;
    padding-bottom: 7rem;
  }
  .video__image {
    width: 65%;
  }
  .video__paragraph {
    margin-bottom: 2rem;
    font-size: 1.25rem;
  }
}

.video__screen {
  position: absolute;
  /*border: 1px solid aquamarine; */              /* for dev purposes... */
  /*background-color: rgba(127, 255, 212, 0.3);*/ /* ...and can be safely removed */
  background-image: url(/static/media/video-screen.c9a179e5.png);
  background-size: cover;
  background-position: center;
  cursor: pointer;
}

.video__screen_small {
  top: 14%;
  left: 8%;
  width: 83%;
  height: 49%;
}

.video__screen_large {
  top: 26%;
  left: 8%;
  right: 90%;
  width: 65.5%;
  height: 42%;
}

@media (min-width: 992px) {
  .video__screen_large {
    top: 26%;
    left: 5%;
    right: 90%;
    width: 39%;
    height: 42%;
  }
}

@media (min-width: 1200px) {
  .video__screen_large {
    top: 26%;
    left: 5.5%;
    right: 90%;
    width: 42.1%;
    height: 42%;
  }
}

.video-modal .ant-modal-content {
  box-shadow: none;
  background-color: transparent;
}
.video-modal .ant-modal-header {
  border: none;
  background-color: transparent;
}
.video-modal .ant-modal-title {
  visibility: hidden;
}

/* OBSOLETE modal video dimensions responsive limits (backup) */
/*@media (min-width: 576px) {
  .video-modal {
    max-width: 560px;
  }
}
@media (min-width: 768px) {
  .video-modal {
    max-width: 720px;
  }
}
@media (min-width: 992px) {
  .video-modal {
    max-width: 960px;
  }
}*/
/*@media (min-width: 1200px) {
  .video-modal {
    max-width: 1140px;
  }
}*/

.youtube-video {
  position: relative;
  width: 90vw;
  padding-bottom: 56.25%;
  padding-top: 30px;
  height: 0;
  overflow: hidden;
}
.youtube-video iframe,
.youtube-video object,
.youtube-video embed {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

@media (min-width: 768px) {
  .youtube-video {
    width: 80vw;
  }
}

.benefit-grid {
  padding-top: 2rem;
  padding-bottom: 2rem;
}

.benefit-grid__heading {
  margin-bottom: 3rem;
  color: #073547;
  color: var(--color-primary, #073547);
  font-size: 2rem;
  font-weight: 500;
  line-height: 1.2;
}

.benefit-grid__item {
  margin-bottom: 3rem;
}


@media (min-width: 576px) {
  .benefit-grid {
    text-align: center;
    padding-top: 3rem;
  }
  .benefit-grid__heading {
    margin-bottom: 4rem;
  }
}

@media (min-width: 768px) {
  .benefit-grid {
    padding-top: 5rem;
    padding-bottom: 1rem;
  }
  .benefit-grid__heading {
    margin-bottom: 5rem;
  }
  .benefit-grid__item {
    margin-bottom: 4rem;
  }
}

@media (min-width: 992px) {
  .benefit-grid {
    padding-top: 7rem;
    padding-bottom: 4rem;
  }
  .benefit-grid__heading {
    margin-bottom: 5rem;
    font-size: 2.75rem;
  }
  .benefit-grid__item {
    margin-bottom: 5rem;
  }
}

.network {
  padding-top: 2rem;
  padding-bottom: 4rem;
  background-color: #073547;
  background-color: var(--color-primary, #073547);
}

/* Text */
.network__text-block {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.network__heading {
  color: white;
  font-size: 2rem;
  font-weight: 500;
}
.network__paragraph {
  margin-bottom: 2rem;
  color: white;
  font-family: 'Open Sans', sans-serif;
}

/* Map */
.network-map {
  position: relative;
  margin: 0 auto 1.5rem;
}
.network-map__map-image {
  width: 100%;
}
.network-map__pin {
  position: absolute;
  width: 2vw;
}

@media (min-width: 576px) {
  .network-map__pin {
    width: .75rem;
  }
}
@media (min-width: 768px) {
  .network {
    padding-top: 3rem;
  }
  .network-map__pin {
    width: 1vw;
  }
}
@media (min-width: 992px) {
  .network {
    padding-bottom: 2rem;
  }
  .network__heading {
    font-size: 2.75rem;
  }
  .network__paragraph {
    font-size: 1.25rem;
  }
  .network-map__pin {
    width: .75rem;
  }
}

.testimonials {
  position: relative;
  background-color: #f2f2f2;
}
.testimonials__top-overlay {
  position: absolute;
  top: 0;
  width: 100%;
  height: 4.5rem;
  background-color: #073547;
  background-color: var(--color-primary, #073547);
}

.testimonial {
  display: flex;
  flex-direction: column;
  margin-bottom: 3rem;
}

.testimonial__photo {
  margin: 0 auto;
  width: 140px;
  height: 140px;
  border-radius: 5px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
  margin-bottom: .95rem;
}
.testimonial__photo-image {
  object-fit: cover;
  width: 100%;
  height: 100%;
  border-radius: 5px;
}

.testimonial__text {
  flex: 1 1;
  text-align: center;
  padding: 1.5rem;
}
.testimonial__name {
  margin-bottom: 1rem;
  color: #073547;
  color: var(--color-primary, #073547);
  font-size: 1.5rem;
  font-weight: 700;
  text-transform: uppercase;
}
.testimonial__feedback {
  color: #073547;
  color: var(--color-primary, #073547);
  line-height: 1.7;
}

@media (min-width: 992px) {
  .testimonials {
    padding-bottom: 1rem;
  }
  .testimonial__text {
    border-radius: 5px;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
    background-color: white;
  }
}

.contact {
  background-size: cover;
  background-position: center;
}

.contact__container {
  text-align: center;
  padding-top: 3rem;
  padding-bottom: 3rem;
}

.contact__heading {
  margin-bottom: 2rem;
  color: #073547;
  color: var(--color-primary, #073547);
  font-size: 2rem;
  font-weight: 500;
  line-height: 1.2;
}

.contact__link {
  color: #ffb332 !important;
  color: var(--color-secondary, #ffb332) !important;
  font-weight: 500;
}

@media (min-width: 768px) {
  .contact__container {
    padding-top: 4rem;
    padding-bottom: 4rem;
  }
}

@media (min-width: 992px) {
  .contact {
    padding-top: 3rem;
    padding-bottom: 3rem;
  }
  .contact__container {
    max-width: 960px !important;
    border-radius: 6px;
    box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.25);
    padding-bottom: 3rem;
    background-color: white;
  }
  .contact__heading {
    margin-bottom: 3rem;
    font-size: 2.75rem;
  }
}

/* The container */
.custom-checkbox {
  position: relative;
  display: inline-block;
  text-align: left;
  margin: 0;
  padding-left: 1.75rem;
  cursor: pointer;
}

/* Hide the browser's default checkbox */
.custom-checkbox__input {
  position: absolute;
  left: 0;
  opacity: 0;
}

/* Create a custom checkbox */
.custom-checkbox__checkmark {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
}

/* Create the checkmark/indicator */
.custom-checkbox__checkmark:after {
  content: '\f0c8';
  color: #3b4143;
  color: var(--predictor-color-primary, #3b4143);
  font-family: 'Font Awesome 5 Free';
  font-weight: 400;
}

/* Show the checkmark when checked */
.custom-checkbox__input:checked ~ .custom-checkbox__checkmark:after {
  content: '\f14a';
  color: #ffb332;
  color: var(--color-secondary, #ffb332);
  font-weight: 700;
}

.thank-you {
  text-align: center;
}

.thank-you__container {
  padding: 2rem 2rem 1rem;
}

.thank-you__image-block {
  margin-bottom: 1.5rem;
}

.thank-you__heading {
  color: #073547;
  color: var(--color-primary, #073547);
  font-size: 1.75rem;
}
.thank-you__paragraph {
  color: #073547;
  color: var(--color-primary, #073547);
  font-family: 'Open Sans', sans-serif;
  line-height: 1.3;
}

@media (min-width: 576px) {
  .thank-you__container {
    display: inline-flex;
    align-items: center;
    padding: 1rem 4rem 1rem 3rem;
  }

  .thank-you__image-block {
    margin-bottom: 0;
  }

  .thank-you__text-block {
    text-align: left;
    margin-left: 2rem;
  }
  .thank-you__heading {
    font-size: 2.5rem;
  }
}

.about-banner {
  position: relative;
}

.about-banner__image {
  width: 100%;
  height: 70vh;
  object-fit: cover;
}

.about-banner__overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: flex-end;
  padding-bottom: 2rem;
}

.about-banner__heading {
  max-width: 20rem;
  color: white;
  font-size: 2rem;
  font-weight: 500;
  line-height: 1.2;
}

@media (min-width: 992px) {
  .about-banner__overlay {
    padding-bottom: 3rem;
  }
  .about-banner__heading {
    max-width: 28rem;
    font-size: 2.75rem;
  }
}

.about-info {
  padding-top: 3rem;
  padding-bottom: 1rem;
}

.about-info__heading {
  margin-bottom: 1.5rem;
  color: #073547;
  color: var(--color-primary, #073547);
  font-size: 2rem;
  font-weight: 500;
  line-height: 1.2;
}

.about-info__paragraph {
  margin-bottom: 2rem;
  color: #073547;
  color: var(--color-primary, #073547);
  font-family: 'Open Sans', sans-serif;
}

.about-info__link {
  color: #ffb332 !important;
  color: var(--color-secondary, #ffb332) !important;
  font-weight: 700;
  text-decoration: underline;
}

@media (min-width: 992px) {
  .about-info {
    padding-top: 6rem;
    padding-bottom: 4rem;
  }
  .about-info__heading {
    margin-bottom: 2rem;
    font-size: 2.75rem;
  }
  .about-info__paragraph {
    font-size: 1.25rem;
  }
}

@media (min-width: 1200px) {
  .about-info {
    padding-right: 7rem;
  }
}

.marketplace__container {
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
}

@media (min-width: 992px) {
  .marketplace__container {
    padding: 2.5rem;
    background-color: #f6f6f6;
  }
}

.marketplace-banner {
  position: relative;
  height: 55vh;
}

.marketplace-banner__image {
  width: 100%;
  height: 100%;  
  object-fit: cover;
}

.marketplace-banner__content {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: flex-end;
  padding-bottom: 1.5rem;
  color: white;
  font-size: 2rem;
  font-weight: 500;
  line-height: 1.2;
}

@media (min-width: 576px) {
  .marketplace-banner__content {
    align-items: center;
    text-align: center;
    width: 35rem;
    margin: auto;
    padding-bottom: 0;
  }
}

@media (min-width: 992px) {
  .marketplace-banner__content {
    width: 45rem;
    font-size: 2.75rem;
  }
}

.marketplace-panel {
  padding: .5rem;
}

.marketplace-panel__separator {
  border-top: 1px solid #efefef;
}

.marketplace-panel__collapse-header {
  padding: .25rem 0;
  color: #64686a;
  font-weight: 500;
}

@media (min-width: 992px) {
  .marketplace-panel {
    border-radius: 6px;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.18);
    padding: 1.5rem 1.25rem;
    background-color: white;
  }
}

.filters__heading {
  color: #073547;
  color: var(--color-primary, #073547);
  font-size: 1rem;
  font-weight: 500;
}
.filters__heading_disabled {
  color: gray;
}

.filters__category-button {
  width: 50%;
  height: 4rem;
  border: 1px solid white;
  background-color: #ffb332;
  background-color: var(--color-secondary, #ffb332);
}
.filters__category-image {
  max-width: 100%;
}

.filters__select-label {
  color: #2f3434;
  font-size: .9rem;
}

.filters__slider-block {
  display: flex;
  align-items: center;
}
.filters__slider-edge {
  padding-top: .25rem;
  color: #232323;
  font-size: .8rem;
}
.filters__slider-wrapper {
  flex: 1 1;
}
.filters__slider-block_disabled > .filters__slider-edge {
  color: gray;
}

.machine-card {
  border-radius: 2px;
  box-shadow:
    0 2px 2px 0 rgba(0, 0, 0, 0.24),
    0 0 2px 0 rgba(0, 0, 0, 0.12);
  margin-bottom: 1.5rem;
  cursor: pointer;
}

.machine-card__media {
  position: relative;
  height: 0;
  padding-bottom: 64%;
  overflow: hidden;
  border-top-left-radius: 2px;
  border-top-right-radius: 2px;
}

.machine-card__image {
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}

.machine-card__like-button {
  position: absolute;
  right: .5rem;
  bottom: .5rem;
  border: none;
  padding: .5rem;
  background: none;
  line-height: 1;
}

.machine-card__like-icon {
  color: #ffb332;
  color: var(--color-secondary, #ffb332);
}

.machine-card__description {
  display: flex;
  padding: 1rem .75rem .75rem;
  background-color: white;
}
.machine-card__left {
  width: 50%;
}
.machine-card__right {
  text-align: center;
  width: 50%;
}
.machine-card__title {
  color: #073547;
  color: var(--color-primary, #073547);
  font-weight: 700;
  text-transform: uppercase;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.machine-card__location {
  color: #999999;
  font-size: .7rem;
}
.machine-card__price {
  color: #ffb332;
  color: var(--color-secondary, #ffb332);
  font-weight: 700;
  white-space: nowrap;
}
.machine-card__forecast {
  color: #073547;
  color: var(--color-primary, #073547);
  font-size: .7rem;
  font-weight: 700;
}
.marketplace-alert {
  position: relative;
  border-radius: 10px;
  padding: 1.5rem;
  padding-right: 2.5rem;
  background-color: #073547;
  background-color: var(--color-primary, #073547);
  color: white;
}

.marketplace-alert__close-button {
  position: absolute;
  top: 0;
  right: 0;
  padding: .75rem 1rem;
  border: none;
  background: none;
  line-height: 1;
}

.marketplace-alert__text-block {
  margin-bottom: 1rem;
}

.marketplace-alert__link {
  color: #ffb332 !important;
  color: var(--color-secondary, #ffb332) !important; /* hover */
  font-weight: 700;
  text-decoration: underline;
  text-transform: uppercase;
}

@media (min-width: 768px) {
  .marketplace-alert__close-button {
    top: .5rem;
    right: .5rem;
  }
}

@media (min-width: 1200px) {
  .marketplace-alert {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: 3rem;
    padding: .75rem 6rem .75rem 2.5rem;
  }

  .marketplace-alert__text-block {
    margin-bottom: 0;
  }

  .marketplace-alert__close-button {
    top: 50%;
    transform: translateY(-50%);
    right: 1rem;
  }
}

/* sign-up modal */
.sign-up-modal {
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
}

@media (min-width: 576px) {
  .sign-up-modal {
    max-width: 560px;
  }
}
@media (min-width: 768px) {
  .sign-up-modal {
    max-width: 720px;
  }
}
@media (min-width: 992px) {
  .sign-up-modal {
    max-width: 800px;
  }
}

.sign-up {
  padding-top: 1.5rem;
}
.sign-up__container {
  max-width: 600px;
  margin: 0 auto 4rem;
}

.sign-up__header {
  text-align: left;
}
.sign-up__heading {
  margin-bottom: 1rem;
  color: #073547;
  color: var(--color-primary, #073547);
  font-size: 2rem;
  font-weight: 500;
  line-height: 1.2;
}
.sign-up__subheading {
  display: inline-block;
  max-width: 24rem;
  margin-bottom: 1rem;
  color: #073547;
  color: var(--color-primary, #073547);
  font-weight: 700;
}

.sign-up__separator {
  margin-bottom: 1rem;
}

.sign-up__group-1 {
  margin-bottom: .25rem;
}
.sign-up__group-2 {
  margin-bottom: 2rem;
}

.sign-up__submit-wrapper {
  text-align: center;
  margin-bottom: 2rem;
}

.sign-up__agree-block {
  text-align: center;
  max-width: 20rem;
  margin: auto;
  color: #073547;
  color: var(--color-primary, #073547);
  font-size: .9rem;
  line-height: 2;
}
.sign-up__agree-link {
  color: #ffb332 !important;
  color: var(--color-secondary, #ffb332) !important;
  font-weight: 500;
}

.sign-up__login-block {
  text-align: center;
  color: #073547;
  color: var(--color-primary, #073547);
  font-size: .9rem;
  font-weight: 500;
  line-height: 1;
}
.sign-up__login-link {
  color: #ffb332 !important;
  color: var(--color-secondary, #ffb332) !important;
}

@media (min-width: 576px) {
  .sign-up {
    padding-left: 1rem;
    padding-right: 1rem;
  }
  .sign-up__header {
    text-align: center;
  }
}

@media (min-width: 768px) {
  .sign-up {
    padding-left: 2.5rem;
    padding-right: 2.5rem;
  }
  .sign-up__subheading {
    margin-bottom: 2rem;
    font-size: 1rem;
  }
  .sign-up__group-1 {
    margin-bottom: 2rem;
  }
  .sign-up__separator {
    width: 90%;
    margin: 0 auto 2.5rem;
  }
  .sign-up__group-2 {
    margin-bottom: 3rem;
  }
  .sign-up__submit-wrapper {
    margin-bottom: 2.5rem;
  }
}

@media (min-width: 992px) {
  .sign-up {
    padding: 2rem 5rem 1rem;
  }
  .sign-up__subheading {
    margin-bottom: 2rem;
  }
}

.thank-you {
  text-align: center;
}

.thank-you__container {
  padding: 2rem 2rem 1rem;
}

.thank-you__image-block {
  margin-bottom: 1.5rem;
}

.thank-you__heading {
  color: #073547;
  color: var(--color-primary, #073547);
  font-size: 1.75rem;
}
.thank-you__paragraph {
  color: #073547;
  color: var(--color-primary, #073547);
  font-family: 'Open Sans', sans-serif;
  line-height: 1.3;
}

@media (min-width: 576px) {
  .thank-you__container {
    display: inline-flex;
    align-items: center;
    padding: 1rem 4rem 1rem 3rem;
  }

  .thank-you__image-block {
    margin-bottom: 0;
  }

  .thank-you__text-block {
    text-align: left;
    margin-left: 2rem;
  }
  .thank-you__heading {
    font-size: 2.5rem;
  }
}

.valuation-header {
  background-image: url(/static/media/background.1934d94f.png);
  background-size: cover;
  background-position: center;
}

.valuation-header__container {
  height: 80vh;
}

/* disabling bootstrap `.container` spacing */
@media (max-width: 991.98px) {
  .valuation-header__container {
    max-width: 100%;  /* overriding the `.container` `max-width` property */
    margin: 0;
    padding-left: 0;
    padding-right: 0;
  }
}

@media (min-width: 992px) {
  .valuation-header__container {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
  }
}

:root {
  --predictor-color-primary: #3b4143;
  --predictor-color-accent: #ffb332;
}

.predictor-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, .5);
}

.predictor-navbar {
  display: flex;
  background-color: #3b4143;
  background-color: var(--color-primary, #3b4143);
}

.predictor-navbar__heading {
  flex: 1 1;
  display: block;
  text-align: center;
  padding: 1rem;
  color: white;
  font-size: 1.25rem;
  font-weight: 900;
}

.predictor-navbar__button-wrapper {
  display: none;  /* when visible - flex */
  align-items: center;
  justify-content: center;
  width: 7rem;
}
.predictor-navbar__button-link {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: nowrap;
  border: none;
  margin: 1rem;
  padding: 0;
  background: none;
  color: #ffb332;
  color: var(--predictor-color-accent, #ffb332);
  font-size: 1rem;
  font-weight: 700;
  line-height: 1.9;
}
@media (min-width: 768px) {
  .predictor-navbar__button-wrapper {
    display: flex;
  }
}

.predictor-progress {
  position: relative;
  z-index: 1;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  width: 100%;
  height: 6px;
  border: none; /* for Firefox */
  background-color: #dedede;  /* for Firefox */
  color: #ffb332;
  color: var(--predictor-color-accent, #ffb332); /* for IE */
}

/* WebKit */
.predictor-progress::-webkit-progress-bar {
  background-color: #dedede;
}
.predictor-progress::-webkit-progress-value {
  background-color: #ffb332;
  background-color: var(--predictor-color-accent, #ffb332);
}

/* Firefox */
.predictor-progress::-moz-progress-bar {
  background-color: #ffb332;
  background-color: var(--predictor-color-accent, #ffb332);
}

/* IE */
.predictor-progress::-ms-fill {
  border: none;
}

.predictor-main {
  flex: 1 1;
  overflow-y: hidden;
  background-color: #ebebeb;
}

.button-select {
  padding: 1.5rem .5rem;
  text-align: center;
  height: 100%;
  overflow-y: scroll;
}

.button-select__grid {
  display: inline-grid;
  grid-template-columns: repeat(2, -webkit-max-content);
  grid-template-columns: repeat(2, max-content);
  grid-gap: .5rem;
  gap: .5rem;
  width: 18.5rem;
}

.button-select__option,
.button-select__option_active {
  display: flex;
  flex-direction: column;
  align-items: center;
  box-sizing: border-box;
  width: 9rem;
  height: 6rem;
  border: none;
  padding: .5rem;
  text-align: center;
  background-color: white;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, .23);
  cursor: pointer;
}
.button-select__option-image-wrapper {
  display: block;
  width: 100%;
  height: 70%;
}
.button-select__option-image {
  max-width: 100%;
  height: 100%;
}
.button-select__option-label {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 30%;
  color: #3b4143;
  color: var(--predictor-color-primary, #3b4143);
  font-size: .9rem;
  font-weight: 700;
  line-height: 1;
}
.button-select__option:active,
.button-select__option_active:active {
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, .5) inset;
}
.button-select__option_active {
  background-color: #ffb332;
  background-color: var(--predictor-color-accent, #ffb332);
}
.button-select__option_active > .button-select__option-label {
  color: white;
}

@media (min-width: 576px) {
  .button-select__grid {
    width: 23rem;
  }

  .button-select__option,
  .button-select__option_active {
    width: 11.25rem;
    height: 7.5rem;
    padding: 1rem 1rem .5rem;
  }
}

@media (min-width: 768px) {
  .button-select__grid {
    grid-template-columns: repeat(3, -webkit-max-content);
    grid-template-columns: repeat(3, max-content);
    width: 34.75rem;
  }
}

@media (min-width: 992px) {
  .button-select__grid {
    grid-template-columns: repeat(4, -webkit-max-content);
    grid-template-columns: repeat(4, max-content);
    width: 46.5rem;
  }
}

.custom-scrollbar {
  /* Firefox */
  scrollbar-width: thin;
  scrollbar-color: rgba(0, 0, 0, .18) #dedede;
  /* MS IE */
  scrollbar-face-color: rgba(0, 0, 0, .18);
  scrollbar-track-color: #dedede;
}

/* WebKit */
.custom-scrollbar::-webkit-scrollbar {
  width: 4px;
}
.custom-scrollbar::-webkit-scrollbar-track {
  border-radius: 3px;
  background-color: #dedede;
}
.custom-scrollbar::-webkit-scrollbar-thumb {
  border-radius: 3px;
  background-color: rgba(0, 0, 0, .18);
}

.step-frame {
  height: 100%;
}

.step-frame__content {
  display: flex;
  flex-direction: column;
  height: 100%;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.36);
  padding: 1rem;
  background-color: #fcfcfc;
}

@media (min-width: 576px) {
  .step-frame__content {
    padding: 1.5rem;
  }
}

@media (min-width: 768px) {
  .step-frame {
    padding: 2rem;
  }
  .step-frame__content {
    padding: 2rem;
  }
}

/* DUPLICATE OF `src/predictor/components/list-select/list-select.css` */

.list-select__list {
  height: 100%;
  border-top: 1px solid #ebebeb;
  margin: 0;
  padding-left: 0;
  padding-right: 1rem;
  list-style: none;
  overflow-y: scroll;
}

.list-select__option {
  border-bottom: 1px solid #ebebeb;
  padding: 1rem 1.5rem;
  color: #3b4143;
  color: var(--predictor-color-primary, #3b4143);
  font-size: 1.1rem;
  font-weight: 500;
  line-height: 1;
  text-decoration: none;
  cursor: pointer;
}
.list-select__option_active {
  background-color: #ffb332;
  background-color: var(--predictor-color-accent, #ffb332);
  color: white;
}

/* this works poorly on touchscreens */
/*.list-select__option:active,
.list-select__option_active:active {
  box-shadow: inset 0 1px 3px 0 rgba(0, 0, 0, 0.5);
}*/


/*.list-select {
  padding-bottom: .5rem !important;
}
@media (min-width: 576px) {
  .list-select {
    padding-bottom: .75rem !important;
  }
}

@media (min-width: 768px) {
  .list-select {
    padding-bottom: 1rem !important;
  }
}*/

.search-bar {
  position: relative;
  margin-bottom: 1rem;
}

.search-bar__input {
  width: 100%;
  height: 3rem;
  border: none;
  border-radius: 3px;
  padding-left: 4rem;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.36);
  background-color: #fcfcfc;
  color: #3b4143;
  color: var(--predictor-color-primary, #3b4143);
}
.search-bar__input::-webkit-input-placeholder {
  color: #3b525d;
  font-weight: 500;
}
.search-bar__input:-ms-input-placeholder {
  color: #3b525d;
  font-weight: 500;
}
.search-bar__input::-ms-input-placeholder {
  color: #3b525d;
  font-weight: 500;
}
.search-bar__input::placeholder {
  color: #3b525d;
  font-weight: 500;
}

.search-bar__icon {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 2rem;
  color: #3b525d;
}

.list-select {
  padding-bottom: .5rem !important;
}

.list-select__list {
  height: 100%;
  border-top: 1px solid #ebebeb;
  margin: 0;
  padding-left: 0;
  padding-right: 1rem;
  list-style: none;
  overflow-y: scroll;
}

.list-select__option,
.list-select__option_active {
  border-bottom: 1px solid #ebebeb;
  padding: 1rem 1.5rem;
  color: #3b4143;
  color: var(--predictor-color-primary, #3b4143);
  font-size: 1.1rem;
  font-weight: 500;
  line-height: 1;
  text-decoration: none;
  cursor: pointer;
}
.list-select__option_active {
  background-color: #ffb332;
  background-color: var(--predictor-color-accent, #ffb332);
  color: white;
}

/* this works poorly on touchscreens */
/*.list-select__option:active,
.list-select__option_active:active {
  box-shadow: inset 0 1px 3px 0 rgba(0, 0, 0, 0.5);
}*/

.list-select__search {
  position: relative;
  margin-bottom: 1rem;
}
.list-select__search-input {
  width: 100%;
  height: 3rem;
  border: none;
  border-radius: 3px;
  padding-left: 4rem;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.36);
  background-color: #fcfcfc;
  color: #3b4143;
  color: var(--predictor-color-primary, #3b4143);
}
.list-select__search-input::-webkit-input-placeholder {
  color: #3b525d;
  font-weight: 500;
}
.list-select__search-input:-ms-input-placeholder {
  color: #3b525d;
  font-weight: 500;
}
.list-select__search-input::-ms-input-placeholder {
  color: #3b525d;
  font-weight: 500;
}
.list-select__search-input::placeholder {
  color: #3b525d;
  font-weight: 500;
}
.list-select__search-icon {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 2rem;
  color: #3b525d;
}

@media (min-width: 576px) {
  .list-select {
    padding-bottom: .75rem !important;
  }
}

@media (min-width: 768px) {
  .list-select {
    padding-bottom: 1rem !important;
  }
}

.checkbox-list {
  padding-bottom: .5rem !important;
}
@media (min-width: 576px) {
  .checkbox-list {
    padding-bottom: .75rem !important;
  }
}
@media (min-width: 768px) {
  .checkbox-list {
    padding-bottom: 1rem !important;
  }
}

/* List Element */
.checkbox-list__list {
  height: 100%;
  border-top: 1px solid #ebebeb;
  margin: 0;
  padding-left: 0;
  padding-right: 1rem;
  list-style: none;
  overflow-y: scroll;
}
.checkbox-list__list-item {
  border-bottom: 1px solid #ebebeb;
}

/* The container */
.custom-checkbox {
  position: relative;
  display: block;
  margin: 0;
  padding-top: 1rem;
  padding-bottom: 1rem;
  padding-left: 3.25rem;
  font-size: 1.1rem;
  line-height: 1;
  cursor: pointer;
}

/* Checkbox textual label */
.custom-checkbox__label {
  color: #3b4143;
  color: var(--predictor-color-primary, #3b4143);
  font-size: 1.1rem;
  font-weight: 500;
}

/* Hide the browser's default checkbox */
.custom-checkbox__input {
  position: absolute;
  width: 0;
  height: 0;
  opacity: 0;
}

/* Create a custom checkbox */
.custom-checkbox__checkmark {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 1.5rem;
}

/* Create the checkmark/indicator */
.custom-checkbox__checkmark:after {
  content: '\f0c8';
  color: #3b4143;
  color: var(--predictor-color-primary, #3b4143);
  font-family: 'Font Awesome 5 Free';
  font-weight: 400;
}

/* Show the checkmark when checked */
.custom-checkbox__input:checked ~ .custom-checkbox__checkmark:after {
  content: '\f14a';
  color: #ffb332;
  color: var(--predictor-color-accent, #ffb332);
  font-weight: 700;
}

.forecast {
  height: 100%;
  overflow-y: scroll;
}

.forecast__loading {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  color: rgba(0, 0, 0, .25);
}

.forecast__spinner {
  margin-bottom: 1rem;
}
@media (min-width: 768px) {
  .forecast__spinner {
    margin-bottom: 3rem;
  }
}

.error-screen__content {
  display: block;
  text-align: center;
  overflow-y: scroll;
}

.error-screen__heading {
  margin-top: 1rem;
}

.error-screen__message-block {
  text-align: center;
  width: auto;
  max-width: 320px;
  margin: 2rem auto 1.5rem;
}
.error-screen__message-large {
  margin-bottom: 1.5rem;
}
.error-screen__message {
  display: inline;
}

.error-screen__contact-block {
  display: flex;
  flex-direction: column;
  margin-bottom: 2.5rem;
}
.error-screen__mail-link {
  color: #ffb332 !important;
  color: var(--predictor-color-accent, #ffb332) !important;
  font-weight: 700;
  text-decoration: underline;
}
.error-screen__phone {
  color: #3b4143;
  color: var(--predictor-color-primary, #3b4143);
}
.error-screen__contact-separator {
  display: none;
  margin: 0 1rem;
  color: #3b4143;
  color: var(--predictor-color-primary, #3b4143);
}

@media (min-width: 576px) {
  .error-screen__content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .error-screen__heading {
    margin-top: 0;
  }
  .error-screen__message-block {
    max-width: none;
  }
  .error-screen__message {
    display: block;
  }
  .error-screen__contact-block {
    flex-direction: row;
    justify-content: center;
  }
  .error-screen__contact-separator {
    display: block;
  }
}

/* typography */
.predictor__heading-1 {
  margin-bottom: 0;
  color: #ffb332;
  color: var(--predictor-color-accent, #ffb332);
  font-size: 2.5rem;
  font-weight: 700;
  line-height: 1.2;
}

.predictor__text {
  color: #3b4143;
  color: var(--predictor-color-primary, #3b4143);
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.3;
}
.predictor__text_large {
  font-size: 1.25rem;
  font-weight: 700;
  line-height: 1.2;
}


/* buttons */
.predictor__button {
  display: inline-block;
  text-align: center;
  min-width: 8.5rem;
  border: none;
  border-radius: 1.5rem;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, .24);
  /*outline: none !important;*/
  padding: .5rem 1rem;
  background: none;
  font-size: 1rem;
  font-weight: 500;
}
.predictor__button:hover {
  text-decoration: none;
  cursor: pointer;
}
.predictor__button:active {
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, .18) inset;
}
.predictor__button_default {
  background-color: #ebebeb;
  color: #3b4143
   !important;
  color: var(
    --predictor-color-primary,
    #3b4143
  ) !important; /* using !important for :hover */
}
.predictor__button_accent {
  background-color: #ffb332;
  background-color: var(--predictor-color-accent, #ffb332);
  color: white !important;  /* using !important for :hover */
}

/* button link */
.predictor__button-link {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  flex-wrap: nowrap;
  border: none;
  padding: 0;
  background: none;
  color: #ffb332;
  color: var(--predictor-color-accent, #ffb332);
  font-size: 1rem;
  font-weight: 700;
  line-height: 1.9;
}


/* forms */
.predictor__input {
  box-sizing: content-box;
  border: none;
  border-bottom: 1px solid #bbb;
  padding-bottom: 1px;
  outline: none;
  color: #3b4143;
  color: var(--predictor-color-primary, #3b4143);
}
.predictor__input::-webkit-input-placeholder {
  color: #bbb;
  font-weight: 500;
}
.predictor__input:-ms-input-placeholder {
  color: #bbb;
  font-weight: 500;
}
.predictor__input::-ms-input-placeholder {
  color: #bbb;
  font-weight: 500;
}
.predictor__input::placeholder {
  color: #bbb;
  font-weight: 500;
}
.predictor__input:hover,
.predictor__input:focus {
  border-bottom-width: 2px;
  padding-bottom: 0;
}
.predictor__input:focus {
  border-bottom-color: #ffb332;
  border-bottom-color: var(--predictor-color-accent, #ffb332);
}

.predictor__input-outline {
  border: none;
  border-radius: 1.5rem;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.36);
  padding: .5rem 1.5rem;
  background-color: #fcfcfc;
  color: #3b4143;
  color: var(--predictor-color-primary, #3b4143);
  font-size: 1rem;
}

.forecast-content {
  min-height: 100%;
  padding: 1rem .5rem;
  background-color: white;
  color: #3b4143;
  color: var(--predictor-color-primary, #3b4143);
}

.forecast-content__main {
  display: block;
  text-align: center;
  max-width: 45rem;
  padding: 1rem 0;
}

.forecast-content__stamp-wrapper {
  width: 6rem;
  height: 6rem;
  margin: 0 auto .75rem;
}
.forecast-content__stamp-image {
  display: inline-block;
  width: 100%;
  height: 100%;
}

.forecast-content__heading {
  width: 18rem;
  margin: auto;
}

.forecast-content__text-large {
  display: inline;
  font-size: 1.25rem;
  font-weight: 700;
  line-height: 1.35;
}

.forecast-content__text-normal {
  font-size: 1rem;
  line-height: 1.5;
}

.forecast-content__price-block {
  margin: 2rem auto;
}

.forecast-content__price-text {
  display: block;
  font-size: 2.5rem;
  font-weight: 700;
  line-height: 1.25;
}

.forecast-content__feedback-text {
  display: block;
  width: 10rem;
  margin: auto;
  font-size: 1rem;
}

.forecast-content__button-block {
  margin: 1.5rem auto 1rem;
}

.forecast-content__hint-block {
  max-width: 45rem;
  padding: 0;
}
.forecast-content__paragraph {
  display: block;
  text-align: justify;
  padding: 0 .5rem;
  font-size: .8rem;
}
.forecast-content__text-warning {
  color: #ffb332;
  color: var(--predictor-color-accent, #ffb332);
  font-weight: 700;
}

@media (min-width: 576px) {
  .forecast-content__heading {
    width: auto;
  }
  .forecast-content__text-large {
    display: block;
    font-size: 1.5rem;
  }
  .forecast-content__text-normal {
    font-size: 1.2rem;
  }
  .forecast-content__price-text {
    display: inline;
  }
  .forecast-content__feedback-text {
    width: auto;
    font-size: 1.2rem;
  }
}

@media (min-width: 768px) {
  .forecast-content {
    padding: 2rem;
    background-color: transparent;
  }
  .forecast-content__main {
    position: relative;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, .36);
    padding-top: 2rem;
    background-color: #fcfcfc;
  }
  .forecast-content__stamp-wrapper {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: -1.5rem;
    width: 8rem;
    height: 8rem;
    margin-bottom: 0;
  }
  .forecast-content__paragraph {
    padding: 0;
  }
}

@media (min-width: 992px) {
  .forecast-content__stamp-wrapper {
    left: -4rem;
  }
}

.forecast-manual__input-container {
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
}
.forecast-manual__input {
  flex: 1 1;
}

@media (min-width: 576px) {
  .forecast-manual__input-container {
    width: 18rem;
    margin-left: auto;
    margin-right: auto;
  }
}

/* The container */
.custom-checkbox-2 {
  position: relative;
  display: inline-block;
  text-align: left;
  margin: 0;
  padding-left: 1.75rem;
  cursor: pointer;
}

/* Hide the browser's default checkbox */
.custom-checkbox-2__input {
  position: absolute;
  left: 0;
  opacity: 0;
}

/* Create a custom checkbox */
.custom-checkbox-2__checkmark {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
}

/* Create the checkmark/indicator */
.custom-checkbox-2__checkmark:after {
  content: '\f0c8';
  color: #3b4143;
  color: var(--predictor-color-primary, #3b4143);
  font-family: 'Font Awesome 5 Free';
  font-weight: 400;
}

/* Show the checkmark when checked */
.custom-checkbox-2__input:checked ~ .custom-checkbox-2__checkmark:after {
  content: '\f14a';
  color: #ffb332;
  color: var(--predictor-color-accent, #ffb332);
  font-weight: 700;
}

.email-sign-up {
  height: 100%;
  overflow-y: scroll;
}

.sign-up__image {
  max-width: 15rem;
}
.sign-up__content-part_image {
  text-align: center;
  margin: 0 0 1.5rem 0;
}
.sign-up__content-part_text {
  text-align: center;
  max-width: 40rem;
  margin: auto;
}

.sign-up__heading {
  margin: 0 auto 1.5rem;
  font-size: 1.75rem;
}

.sign-up__checkbox-wrapper {
  max-width: 16rem;
  margin: 0 auto 1rem;
}

@media (min-width: 576px) {
  .sign-up__heading {
    font-size: 2rem;
  }
  .sign-up__checkbox-wrapper {
    max-width: none;
  }
}

@media (min-width: 992px) {
  .sign-up__content {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    height: 100%;
  }

  .sign-up__image {
    max-width: 100%;
  }
  .sign-up__content-part_image {
    width: 48%;
    margin: 0;
    padding-left: 1rem;
    padding-right: 3rem;
  }
  .sign-up__content-part_text {
    text-align: left;
    width: 52%;
  }
}

.finish-step {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.finish-step__message {
  text-align: center;
  width: auto;
  max-width: 20rem;
  margin-top: 2rem;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 0;
}

.finish-step__button-block {
  text-align: center;
  margin-top: 2.5rem;
}

.loading-screen {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.loading-screen__spinner {
  color: rgba(0, 0, 0, .25);
}
@media (min-width: 768px) {
  .loading-screen__spinner {
    margin-bottom: 2rem;
  }
}

.predictor-footer {
  display: flex;
  padding: .75rem 1rem;
  background-color: #3b4143;
  background-color: var(--color-primary, #3b4143);
}

.predictor-footer__button {
  width: 7.25rem;
  height: 2.75rem;
  border: none;
  border-radius: 22px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, .5);
  /*outline: none !important;*/
  background-color: #3b4143;
  background-color: var(--color-primary, #3b4143);
  color: #ffb332;
  color: var(--predictor-color-accent, #ffb332);
  font-weight: 700;
}
.predictor-footer__button:active {
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, .5) inset;
}

@media (min-width: 768px) {
  .predictor-footer {
    display: none;
  }
}

.laptop {
  padding: 2.5rem 0;
  overflow-x: hidden;
  background-color: #073547;
  background-color: var(--color-primary, #073547);
}

.laptop__heading {
  margin-bottom: 1rem;
  color: white;
  font-size: 2rem;
  font-weight: 500;
  line-height: 1.2;
}

.laptop__text {
  margin-bottom: 2rem;
  padding-right: 1rem;
  color: white;
  font-family: 'Open Sans', sans-serif;
  line-height: 1.7;
}

.laptop__show-link {
  display: inline-flex;
}
.laptop__show-link-text {
  margin-left: .5rem;
  color: #ffb332;
  color: var(--color-accent, #ffb332);
  font-size: 1.1rem;
  font-weight: 700;
  text-decoration: underline;
}

.laptop__image-wrapper {
  text-align: right;
  margin-bottom: 2rem;
}
.laptop__image {
  width: 90%;
}

@media (min-width: 576px) {
  .laptop__image-wrapper {
    position: absolute;
    top: 0;
    left: 68%;
    display: flex;
    align-items: center;
    height: 100%;
  }
  .laptop__image {
    width: auto;
    height: 72vw;
    max-height: 100%;
    margin-top: 1rem;
    margin-bottom: -.5rem;
  }
  .laptop__text {
    padding-right: 4rem;
  }
}
@media (min-width: 768px) {
  .laptop {
    padding: 5rem 0;
  }
  .laptop__image-wrapper {
    left: 60%;
  }
}
@media (min-width: 992px) {
  .laptop {
    padding: 5rem 0;
  }
  .laptop__image-wrapper {
    left: 55%;
  }
  .laptop__heading {
    font-size: 2.75rem;
  }
  
}
@media (min-width: 1200px) {
  .laptop {
    padding: 7rem 0;
  }
  .laptop__image-wrapper {
    left: auto;
    right: 0;
  }
  .laptop__text {
    font-size: 1.25rem;
  }
}

.main_layout__2cfNk {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.main_content__2Y6pH {
  flex: 1 1;
}

.top {
  display: flex;
  justify-content: flex-end;
  padding: .75rem;
  color: white;
  background-color: #073547;
  background-color: var(--color-primary, #073547);
}

.top__text {
  display: none;
}

.top__contact {
  display: inline-flex;
  align-items: center;
}
.top__contact-icon {
  margin-right: .5rem;
}
.top__contact-text {
  color: white !important;  /* mailto link hover */
  font-size: .8rem;
  font-weight: 700;
  line-height: 1;
  white-space: nowrap;
}

@media (min-width: 768px) {
  .top__text {
    margin-right: .75rem;
    display: inline;
    font-size: .9rem;
    white-space: nowrap;
  }
  .top__contact-text {
    font-size: .9rem;
  }
}
@media (min-width: 992px) {
  .top {
    padding: .75rem 1.5rem;
  }
  .top__text {
    font-size: 1rem;
  }
  .top__contact {
    margin-left: 1.5rem;
  }
  .top__contact-text {
    font-size: 1rem;
  }
}

.navbar {
  position: -webkit-sticky;
  position: sticky;
  z-index: 1000;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: .75rem .5rem .75rem 1rem;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.24);
  background-color: white;
}

/* brand (logo) */
.navbar__brand {
  display: block;
  width: 11rem;
  padding: .25rem 0;
}

/* mobile menu button */
.navbar__menu-button {
  padding: .5rem .5rem 0rem;
  border: none;
  background: none;
  line-height: 1;
}
.navbar__menu-icon {
  color: #073547;
  color: var(--color-primary, #073547);
  font-size: 1.5rem;
}

/* desktop expandable section */
.navbar__expand {
  display: none;
}
.navbar__signup-button {
  display: none;
}
.navbar__signup-button-expand {
  display: none;
}

@media (min-width: 768px) {
  .navbar {
    padding: 1rem 1rem 1.25rem 2rem;
  }
}
@media (min-width: 992px) {
  .navbar {
    padding-right: 2rem;
  }
  .navbar__brand {
    width: 14.5rem;
  }
  .navbar__menu-button {
    display: none;
  }
  .navbar__expand {
    display: block;
  }
}
@media (min-width: 1200px) {
  .navbar__signup-button {
    display: inline-block;
    margin-left: 1.5rem;
  }
}
@media (min-width: 1350px) {
  .navbar__signup-button-expand {
    display: inline;
  }
}

/* overriding default Ant styles */
.ant-drawer-body {
  height: 100%;
}
.ant-drawer-content {
  background-color: #073547;
  background-color: var(--color-primary, #073547);
  color: white;
}


/* custom styles */
.custom-drawer__content {
  display: flex;
  flex-direction: column;
  text-align: right;
  height: 100%;
}

.custom-drawer__separator {
  border-top: 1px solid rgba(151, 151, 151, 0.48);
}

.custom-drawer__close-button {
  border: none;
  background: none;
}

.custom-drawer__logo-link {
  display: inline-block;
  width: 12rem;
  padding: 1.5rem 0;
}

.custom-drawer__logo-image {
  width: 100%;
  pointer-events: none;
}

/* menu */
.menu {
  display: inline-flex;
  flex-direction: column;
  list-style: none;
  margin: 0;
  padding: 0;
}

.menu__item {
  padding-top: 1.25rem;
  line-height: 1;
}
.menu__item::after {
  content: '';
  display: block;
  width: 1.25rem;
  height: 4px;
  border-radius: 10px;
  margin-top: .4rem;
  margin-left: auto;
}
.menu__item_active::after {
  background-color: #ffb332;
  background-color: var(--color-secondary, #ffb332);
}

.menu__link {
  padding: .5rem 0;
  color: #2F3434;
  font-size: 1rem;
  font-weight: 500;
}
.menu__link:hover {
  color: #2F3434;
  text-decoration: none;
}
.menu_inverse .menu__link {
  color: white !important;
}

@media (min-width: 992px) {
  .menu {
    flex-direction: row;
  }
  .menu__item:not(:last-child) {
    margin-right: 2rem;
  }
  .menu__item::after {
    margin-top: .5rem;
    margin-right: auto;
  }
}

.navbar-logo {
  position: relative;
}

.navbar-logo__image {
  width: 100%;
}

.navbar-logo__secondary-image {
  position: absolute;
  top: 105%;
  right: 0;
  height: .75rem;
}

@media (min-width: 768px) {
  .navbar-logo__secondary-image {
    height: .8rem;
  }
}
@media (min-width: 992px) {
  .navbar-logo__secondary-image {
    height: 1rem;
  }
}

.footer {
  padding-top: 3rem;
  background-color: #073547;
  background-color: var(--color-primary, #073547);
  color: white;
}

.footer__text {
  display: block;
  font-size: 1.25rem;
  line-height: 1.3;
}
.footer__text_light {
  font-weight: 300;
}
.footer__text_medium {
  font-weight: 500;
}

.footer__separator {
  border-top: 1px solid rgba(151, 151, 151, 0.46);
  margin-bottom: 1.5rem;
}

.footer__heading {
  margin-bottom: 1.5rem;
  color: #ffb332;
  color: var(--color-accent, #ffb332);
  font-weight: 700;
}

.footer__link-wrapper {
  margin-bottom: 1rem;
}
.footer__link {
  color: white !important;
  font-weight: 500;
  text-decoration: underline;
}


/* CODE COPIED FROM THE TOP COMPONENT */
.footer__contact {
  display: flex;
  align-items: flex-start;
  margin-bottom: 1.5rem;
}
.footer__contact-icon-wrapper {
  text-align: center;
  width: 1.5rem;
  margin-right: .75rem;
}
.footer__contact-text {
  color: white !important;  /* mailto link */
  font-weight: 700;
}
/* END COPIED CODE */


@media (min-width: 768px) {
  .footer__text {
    display: inline;
  }
  .footer__separator {
    border-top-color: transparent;
  }
}
@media (min-width: 992px) {
  .footer {
    padding-top: 3.5rem;
  }
}

.imprint {
  padding-top: 2rem;
  padding-bottom: 2rem;
}

.imprint > p {
  color: #2f3434;
  font-family: 'Open Sans', sans-serif;
}

.imprint > p > b {
  color: #073547;
  color: var(--color-primary, #073547);
}

.imprint__heading {
  margin-bottom: 1.5rem;
  color: #073547;
  color: var(--color-primary, #073547);
  font-size: 2rem;
  line-height: 1.2;
}

@media (min-width: 992px) {
  .imprint {
    padding-top: 4rem;
    padding-bottom: 4rem;
  }
  .imprint__heading {
    font-size: 2.75rem;
  }
  .imprint > p {
    margin-bottom: 1.5rem;
    font-size: 1.25rem;
  }
}

.terms-of-service {
  padding-top: 2rem;
  padding-bottom: 2rem;
}

.terms-of-service__separator {
  margin-bottom: 2rem;
}

@media (min-width: 992px) {
  .terms-of-service {
    max-width: 960px !important;  /* overriding bootstrap container */
    padding-top: 4rem;
    padding-bottom: 4rem;
  }
  .terms-of-service__separator {
    margin-bottom: 3rem;
  }
}

/* 
  FILE CONTENTS COPIED FROM "../terms-of-service/terms-of-service.css"
*/

.privacy-policy {
  padding-top: 2rem;
  padding-bottom: 2rem;
}

.privacy-policy__separator {
  margin-bottom: 2rem;
}

@media (min-width: 992px) {
  .privacy-policy {
    max-width: 960px !important;  /* overriding bootstrap container */
    padding-top: 4rem;
    padding-bottom: 4rem;
  }
  .privacy-policy__separator {
    margin-bottom: 3rem;
  }
}

.sign-up-page {
  display: flex;
  align-items: center;
  text-align: center;
  min-height: 70vh;
  padding-top: 1rem;
  padding-bottom: 2rem;
}

.sign-up-page__container {
  display: inline-block;
}

@media (min-width: 768px) {
  .sign-up-page__container {
    padding: 1rem 0;
  }
}
@media (min-width: 992px) {
  .sign-up-page {
    padding-top: 3rem;
    padding-bottom: 4rem;
  }
  .sign-up-page__container {
    box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.25);
    border-radius: 6px;
    padding: 1rem;
  }
}

/* The buttons styles have been copied from the Handler1 global styles (initially). */

/* buttons */
.dshb-button {
  display: inline-block;
  border: 1px solid #ffb332;
  border: 1px solid var(--dshb-color-secondary, #ffb332);
  border-radius: 2.5rem;
  padding: .75rem 1.5rem;
  background-color: #ffb332;
  background-color: var(--dshb-color-secondary, #ffb332);
  color: white !important; /* link hover */
  font-size: .9rem;
  font-weight: 700;
  text-decoration: none !important; /* link hover */
}

.dshb-button_outline {
  background-color: transparent;
  color: #ffb332 !important;
  color: var(--dshb-color-secondary, #ffb332) !important; /* link hover */
}

.dshb-button_round {
  display: inline-flex;
  align-items: center;
  justify-content: center;  
  width: 2.5rem;
  height: 2.5rem;
  padding: 0;
}

.dshb-input, .dshb-select {
  width: 100%;
  border: 1px solid #3b4143;
  border: 1px solid var(--dshb-color-primary, #3b4143);
  border-radius: 3px;
  padding: .5rem .75rem;
}
.dshb-input::-webkit-input-placeholder {
  color: #969696;
}
.dshb-input:-ms-input-placeholder {
  color: #969696;
}
.dshb-input::-ms-input-placeholder {
  color: #969696;
}
.dshb-input::placeholder {
  color: #969696;
}

.dshb-select__wrapper {
  position: relative;
}
.dshb-select__icon {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: .75rem;
  color: #3b4143;
  color: var(--dshb-color-primary, #3b4143);
  font-size: .75rem;
  pointer-events: none;
}
.dshb-select {
  padding-right: 1.75rem;
  background-color: white;
  color: #3b4143;
  color: var(--dshb-color-primary, #3b4143);
  font-style: italic;
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
}

:root {
  --dshb-color-primary: #3b4143;
  --dshb-color-secondary: #ffb332;
}

.evaluator_evaluator__2pl7b {
  padding-top: 2rem;
  padding-bottom: 2rem;
  background-color: white;
}

.evaluator_title__EAhdA {
  color: #073547;
  color: var(--dshb-color-primary, #073547);
  font-size: 2rem;
  font-weight: 500;
  line-height: 1.2;
}

.evaluator_separator__3uvnn {
  width: 100%;
  height: 0;
  border-top: 1px solid #e6e6e6;
  margin-bottom: 2.0rem;
}

@media (min-width: 992px) {
  .evaluator_evaluator__2pl7b {
    padding-top: 4rem;
    padding-bottom: 4rem;
  }
  .evaluator_title__EAhdA {
    font-size: 2.75rem;
  }
}

.form_form__2ryXz {
  display: grid;
  grid-template-columns: 1fr;
  grid-row-gap: 2rem;
  row-gap: 2rem;
}

.form_checkbox_group__1TONM {
  height: 10rem;
  overflow-y: scroll;
}

.form_group__1gKvm {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.form_group__1gKvm:not(:last-child) {
  margin-bottom: 1rem;
}

.form_label__1lw5m {
  margin-right: .5rem;
  margin-bottom: 0;
  color: #073547;
  color: var(--dshb-color-primary, #073547);
  font-weight: 500;
  white-space: nowrap;
}

.form_control_wrapper__1qwma {
  width: 50%;
}
.form_control__2feJ_ {
  max-width: 50%;
}

.form_checkbox_group_label__3X0dk {
  margin: .5rem 0 .75rem;
  color: #073547;
  color: var(--dshb-color-primary, #073547);
  font-weight: 500;
}

.form_checkbox_label__3wvi7 {
  color: #073547;
  color: var(--dshb-color-primary, #073547);
  font-size: 1rem;
}

.form_submit_wrapper__35bvv {
  display: inline-flex;
  flex-direction: column;
  justify-content: center;
}


@media (min-width: 768px) {
  .form_form__2ryXz {
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 2rem;
    -webkit-column-gap: 2rem;
            column-gap: 2rem;
  }
}

@media (min-width: 992px) {
  .form_form__2ryXz {
    grid-template-columns: repeat(3, 1fr);
    grid-row-gap: 3rem;
    row-gap: 3rem;
    grid-column-gap: 3rem;
    -webkit-column-gap: 3rem;
            column-gap: 3rem;
  }

  .form_group__1gKvm:not(:last-child) {
    margin-bottom: 2rem;
  }
  .form_control__2feJ_ {
    width: 10rem !important;
    max-width: none;
  }
  .form_control_wrapper__1qwma {
    width: 10rem;
  }

  .form_checkbox_group__1TONM {
    height: 9rem;
  }
}

@media (min-width: 1200px) {
  .form_form__2ryXz {
    grid-template-columns: repeat(4, 1fr);
    grid-column-gap: 2rem;
    -webkit-column-gap: 2rem;
            column-gap: 2rem;
  }
}

.custom-scrollbar {
  /* Firefox */
  scrollbar-width: thin;
  scrollbar-color: rgba(0, 0, 0, .18) #dedede;
  /* MS IE */
  scrollbar-face-color: rgba(0, 0, 0, .18);
  scrollbar-track-color: #dedede;
}

/* WebKit */
.custom-scrollbar::-webkit-scrollbar {
  width: 4px;
}
.custom-scrollbar::-webkit-scrollbar-track {
  border-radius: 3px;
  background-color: #dedede;
}
.custom-scrollbar::-webkit-scrollbar-thumb {
  border-radius: 3px;
  background-color: rgba(0, 0, 0, .18);
}

/* The container */
.checkbox_checkbox__3vEEr {
  position: relative;
  display: inline-block;
  text-align: left;
  margin: 0;
  padding-left: 1.75rem;
  cursor: pointer;
}

/* Hide the browser's default checkbox */
.checkbox_checkbox_input__2vF0L {
  position: absolute;
  left: 0;
  opacity: 0;
}

/* Create a custom checkbox */
.checkbox_checkbox_checkmark__2lZmc {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
}

/* Create the checkmark/indicator */
.checkbox_checkbox_checkmark__2lZmc:after {
  content: '\f0c8';
  color: #3b4143;
  color: var(--dshb-color-primary, #3b4143);
  font-family: 'Font Awesome 5 Free';
  font-weight: 400;
}

/* Show the checkmark when checked */
.checkbox_checkbox_input__2vF0L:checked ~ .checkbox_checkbox_checkmark__2lZmc:after {
  content: '\f14a';
  color: #3b4143;
  color: var(--dshb-color-primary, #3b4143);
  font-weight: 700;
}

.loading_loading__1hTSt {
  text-align: center;
  color: #3b4143;
  color: var(--dshb-color-primary, #3b4143);
}

.loading_loading_text__1i_96 {
  margin-top: .5rem;
  color: #3b4143;
  color: var(--dshb-color-primary, #3b4143);
}

.results_separator_1__34-Mt, .results_separator_2__1PJME {
  width: 100%;
  height: 0;
  border-top: 1px solid #e6e6e6;
  margin: 1rem 0;
}

.results_price_blocks__uLN64 {
  display: inline-block;
}
.results_plot_section__3uYqT {
  margin-bottom: 2rem;
}
.results_counter_section__1UiEN {
  margin-bottom: 2rem;
}

.results_buttons_section__23yGl {
  text-align: left;
}

@media (min-width: 768px) {
  .results_price_section__3o4_U {
    float: left;
    width: 35%;
  }
  .results_plot_section__3uYqT {
    float: right;
    width: 60%;
  }
  .results_counter_section__1UiEN {
    float: left;
    width: 35%;
  }
  .results_buttons_section__23yGl {
    clear: both;
    text-align: right;
  }
  .results_separator_2__1PJME {
    margin: 2rem 0;
  }
}

@media (min-width: 992px) {
  .results_separator_1__34-Mt {
    margin: 1.5rem 0;
  }
  .results_buttons_section__23yGl {
    clear: none;
    text-align: left;
  }
}

.price-block_block__1j_TE {
  color: #3b4143;
  color: var(--dshb-color-primary, #3b4143);
}

.price-block_title__18Kfm {
  color: inherit;
  font-size: 1rem;
  font-weight: 500;
}

.price-block_hint__1YIF0 {
  margin-bottom: .75rem;
  color: inherit;
  font-size: .9rem;
  font-weight: 400;
  line-height: 1;
}

.price-block_range__3khD8 {
  color: inherit;
  font-size: 1.25rem;
  font-weight: 700;
}

.plot_wrapper__1AarP {
  position: relative;
  width: 100%;
  height: 0;
  padding-bottom: 80%;
}

.plot_plot__Dz2jl {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.plot_plot__Dz2jl summary {
  z-index: 999 !important;
}

.counter_label__2ozMD {
  margin-bottom: .5rem;
  color: #3b4143;
  color: var(--dshb-color-primary, #3b4143);
  font-weight: 500;
}

.counter_counter_wrapper__3_gl8 {
  display: flex;
}

.counter_input__Cytny {
  flex: 1 1;
  margin: 0 .5rem;
}

.operator_operator__1301t {
  text-align: center;
  padding: 1rem 0;
}

.operator_heading__1ufED {
  margin-bottom: 1.5rem;
  color: #3b4143;
  color: var(--dshb-color-primary, #3b4143);
  font-size: 1.5rem;
  font-weight: 700;
}

.operator_name__1NjDu {
  margin-bottom: .25rem;
  color: #6f6f6f;
  font-size: 1.25rem;
  font-weight: 500;
}

.operator_phone_block__1yaXx {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 2rem;
}
.operator_phone_text__1h9s9 {
  margin-left: .5rem;
  color: #3b4143;
  color: var(--dshb-color-primary, #3b4143);
  font-size: 1rem;
  font-weight: 700;
}

.verify {
  text-align: center;
  padding-top: 2rem;
  padding-bottom: 2rem;
}

.verify__heading {
  margin-bottom: 1rem;
  color: #073547;
  color: var(--color-primary, #073547);
  font-size: 2rem;
  font-weight: 700;
  line-height: 1.2;
}
.verify__subheading {
  max-width: 24rem;
  margin: 0 auto 3rem;
  color: #073547;
  color: var(--color-primary, #073547);
}

.verify__input-wrapper {
  position: relative;
  max-width: 23.5rem;
  margin: 0 auto 4rem;
}
.verify__incorrect {
  position: absolute;
  left: 1.5rem;
  color: #c71e1e;
}
.verify__submit-wrapper {
  margin-bottom: 1.5rem;
}

.verify__hint {
  max-width: 28.5rem;
  margin: auto;
  color: #073547;
  color: var(--color-primary, #073547);
  font-size: .8rem;
}
.verify__text-accent {
  color: #ffb332;
  color: var(--color-secondary, #ffb332);
  font-weight: 700;
}


@media (min-width: 576px) {
  .verify {
    padding-top: 3rem;
    padding-bottom: 3rem;
  }
}

@media (min-width: 992px) {
  .verify {
    padding-top: 4rem;
    padding-bottom: 5rem;
  }
  .verify__heading {
    font-size: 2.75rem;
  }
  .verify__subheading {
    margin-bottom: 4.5rem;
  }
  .verify__input-wrapper {
    margin-bottom: 4.5rem;
  }
  .verify__submit-wrapper {
    margin-bottom: 2rem;
  }
}

.not-found {
  padding-top: 5rem;
  padding-bottom: 5rem;
}

.cookie-bar_cookie_bar__XLi3n {
  position: fixed;
  z-index: 1;
  left: 0;
  right: 0;
  bottom: 0;
}

.cookie-bar_container__294UM {
  text-align: center;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  box-shadow: -3px -2px 4px 0 rgba(0, 0, 0, 0.28);
  padding: .5rem .5rem 0;
  background-color: var(--color-primary);
  color: white;
}

.cookie-bar_text_block__-pWgt {
  max-width: 40rem;
  margin: 0 auto .5rem;
}
.cookie-bar_link__SqgGz {
  color: #ffb332 !important;
  color: var(--color-secondary, #ffb332) !important;  /* hover */
  font-weight: 500;
  text-decoration: underline;
}

@media (min-width: 992px) {
  .cookie-bar_container__294UM {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .cookie-bar_text_block__-pWgt {
    margin: 0 1.5rem .5rem 0;
  }
}

.dashboard_layout__3g3T6 {
  display: flex;
}
.dashboard_left__8_Emb {
  display: none;
}
.dashboard_right__3P73n {
  flex: 1 1;
  padding: 0 1rem;
}

.dashboard_navbar_wrapper__3ajjY {
  position: -webkit-sticky;
  position: sticky;
  z-index: 1000;
  top: 0;
}

.dashboard_right_container__3YlVc {
  padding-top: 1rem;  /* remove when Toolbar appears */
  padding-bottom: 1rem;
}


@media (min-width: 768px) {
  .dashboard_main__3KYBP {
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.26);
    padding: 1rem;
  }
}


@media (min-width: 992px) {
  .dashboard_layout__3g3T6 {
    min-height: 100vh;
  }
  .dashboard_navbar_wrapper__3ajjY {
    display: none;
  }
  .dashboard_left__8_Emb {
    display: flex;
    flex-direction: column;
    box-shadow: 2px 2px 4px 0 rgba(65, 65, 65, 0.49);
  }
  .dashboard_right__3P73n {
    padding: 0 1.5rem;
  }
  .dashboard_right_container__3YlVc {
    display: flex;
    flex-direction: column;
    height: 100%;
    padding-top: 1.5rem;  /* remove when Toolbar appears */
    padding-bottom: 1.5rem;
  }
  .dashboard_main__3KYBP {
    height: 100%;
    padding: 1rem 1.5rem;
  }
}


@media (min-width: 1200px) {
  .dashboard_right__3P73n {
    padding: 0 2rem;
  }
  .dashboard_main__3KYBP {
    padding: 1rem 2rem;
  }
}

.sidebar_sidebar__1mac7 {
  height: 100%;
  padding: 1.5rem 1.5rem 0;
  background-color: #3b4143;
  background-color: var(--dshb-color-primary, #3b4143);
  color: white;
  font-size: .8rem;
}

.sidebar_separator__3DUp_ {
  width: 100%;
  border-top: 1px solid rgba(255, 255, 255, .25);
  margin: 0 auto 1rem;
}

.sidebar_coming_soon_bold__3GRTa {
  color: #ffb332;
  color: var(--dshb-color-secondary, #ffb332);
  font-weight: 500;
  line-height: 1.25;
}

.menu_menu__3Ct8z {
  margin: 0;
  padding: 0;
  list-style: none;
}

.menu_menu_item__3_H8D {
  margin-bottom: .5rem;
}

/* general menu item */
.item_item__kOXy5 {
  display: flex;
  align-items: center;
  height: 2rem;
}

.item_item_image_wrapper__23Y1A {
  text-align: center;
  width: 1.5rem;
}
.item_item_image__1vp9j {
  max-width: 100%;
  max-height: 1.35rem;
}

.item_item_text__3rnHI {
  padding-left: 1.25rem;
}
.item_item__kOXy5:hover > .item_item_text__3rnHI {
  text-decoration: underline;
}


/* menu button */
.item_menu_button__1ryAQ {
  display: block;
  width: 100%;
  border: none;
  padding: 0;
  background: none;
}
.item_menu_button__1ryAQ:disabled {
  opacity: .5;
  cursor: not-allowed;
}
.item_menu_button__1ryAQ:disabled > .item_item__kOXy5 > .item_item_text__3rnHI {
  text-decoration: none;
}


/* menu button */
.item_menu_link__1LmaK {
  display: block;
  width: 100%;
  color: white !important;  /* hover */
}

.toolbar_toolbar__3KJHp {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1.5rem 0;
}

.back_back__1oxFo {
  display: inline-flex;
  align-items: center;
  cursor: pointer;
}

.back_text__3lTGq {
  margin: 0 .5rem;
  color: #a5a5a5;
  font-weight: 500;
}
.back_back__1oxFo:hover > .back_text__3lTGq {
  text-decoration: underline;
}

.navbar_navbar__3DmLY {
  display: flex;
  align-items: center;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.24);
  padding: .55rem 1rem;
  background-color: white;
}

.navbar_menu_button__r5ity {
  border: none;
  margin-top: .5rem;
  margin-right: 1rem;
  padding-top: .25rem;
  background: none;
}
.navbar_menu_icon__1fWtH {
  color: #3b4143;
  color: var(--dshb-color-primary, #3b4143);
  font-size: 1.5rem;
  line-height: 1;
}

.navbar_logo__1jgnN {
  height: 1.5rem;
}

.custom-drawer_header__2n18P {
  padding: 1rem;
  background-color: white;
}

.custom-drawer_close_button__36lEL {
  border: none;
  background: none;
}

.evaluator-next_evaluator__34zOy {
  padding: 0;
  background-color: white;
}

.evaluator-next_title__3k57f {
  color: #3b4143;
  color: var(--dshb-color-primary, #3b4143);
  font-size: 2rem;
  font-weight: 500;
  line-height: 1.2;
}

.evaluator-next_separator__3xN9x {
  width: 100%;
  height: 0;
  border-top: 1px solid #e6e6e6;
  margin-bottom: 2.0rem;
}

@media (min-width: 992px) {
  .evaluator-next_evaluator__34zOy {
    max-width: none;
  }
}

.form_form__39VDH {
  display: grid;
  grid-template-columns: 1fr;
  grid-row-gap: 2rem;
  row-gap: 2rem;
}

.form_checkbox_group__2rpRy {
  height: 10rem;
  overflow-y: scroll;
}

.form_group__SF8BO {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.form_group__SF8BO:not(:last-child) {
  margin-bottom: 1rem;
}

.form_label__2cV6J {
  margin-right: .5rem;
  margin-bottom: 0;
  color: #3b4143;
  color: var(--dshb-color-primary, #3b4143);
  font-weight: 500;
  white-space: nowrap;
}

.form_control_wrapper__1lEt0 {
  width: 50%;
}
.form_control__2I1vX {
  max-width: 50%;
}

.form_checkbox_group_label__tMvYq {
  margin: .5rem 0 .75rem;
  color: #3b4143;
  color: var(--dshb-color-primary, #3b4143);
  font-weight: 500;
}

.form_checkbox_label__2LyIh {
  color: #3b4143;
  color: var(--dshb-color-primary, #3b4143);
  font-size: 1rem;
}

.form_submit_wrapper__2CL8s {
  display: inline-flex;
  flex-direction: column;
  justify-content: center;
}


@media (min-width: 768px) {
  .form_form__39VDH {
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 2rem;
    -webkit-column-gap: 2rem;
            column-gap: 2rem;
  }
}

@media (min-width: 1300px) {
  .form_form__39VDH {
    grid-template-columns: repeat(3, 1fr);
    grid-row-gap: 3rem;
    row-gap: 3rem;
    grid-column-gap: 3rem;
    -webkit-column-gap: 3rem;
            column-gap: 3rem;
  }

  .form_group__SF8BO:not(:last-child) {
    margin-bottom: 2rem;
  }
  .form_control__2I1vX {
    width: 10rem !important;
    max-width: none;
  }
  .form_control_wrapper__1lEt0 {
    width: 10rem;
  }

  .form_checkbox_group__2rpRy {
    height: 9rem;
  }
}

@media (min-width: 1600px) {
  .form_form__39VDH {
    grid-template-columns: repeat(4, 1fr);
    grid-column-gap: 2rem;
    -webkit-column-gap: 2rem;
            column-gap: 2rem;
  }
}

.verify {
  text-align: center;
  padding-top: 2rem;
  padding-bottom: 2rem;
}

.verify__heading {
  margin-bottom: 1rem;
  color: #073547;
  color: var(--color-primary, #073547);
  font-size: 2rem;
  font-weight: 700;
  line-height: 1.2;
}
.verify__subheading {
  max-width: 24rem;
  margin: 0 auto 3rem;
  color: #073547;
  color: var(--color-primary, #073547);
}

.verify__input-wrapper {
  position: relative;
  max-width: 23.5rem;
  margin: 0 auto 4rem;
}
.verify__incorrect {
  position: absolute;
  left: 1.5rem;
  color: #c71e1e;
}
.verify__submit-wrapper {
  margin-bottom: 1.5rem;
}

.verify__hint {
  max-width: 28.5rem;
  margin: auto;
  color: #073547;
  color: var(--color-primary, #073547);
  font-size: .8rem;
}
.verify__text-accent {
  color: #ffb332;
  color: var(--color-secondary, #ffb332);
  font-weight: 700;
}


@media (min-width: 576px) {
  .verify {
    padding-top: 3rem;
    padding-bottom: 3rem;
  }
}

@media (min-width: 992px) {
  .verify {
    padding-top: 4rem;
    padding-bottom: 5rem;
  }
  .verify__heading {
    font-size: 2.75rem;
  }
  .verify__subheading {
    margin-bottom: 4.5rem;
  }
  .verify__input-wrapper {
    margin-bottom: 4.5rem;
  }
  .verify__submit-wrapper {
    margin-bottom: 2rem;
  }
}

/* EMAIL TEMPLATE TYPOGRAPHY */
.email-tmpl__article h2 {
  margin-bottom: 1.25rem;
  color: #3b4143;
  color: var(--color-primary, #3b4143);
  font-size: 2rem;
  line-height: 1.2;
}
.email-tmpl__article p {
  text-align: justify;
  color: #3b4143;
  color: var(--color-primary, #3b4143);
  font-family: 'Open Sans', sans-serif;
  font-size: 1rem;
}
.email-tmpl__article a {
  color: #ffb332 !important;
  color: var(--color-secondary, #ffb332) !important;  /* hover */
  font-weight: 700;
  text-decoration: underline;
  word-wrap: break-word;
}

.email-tmpl__heading {
  margin-bottom: 1.25rem;
  color: #3b4143;
  color: var(--color-primary, #3b4143);
  font-size: 2rem;
  line-height: 1.2;
}
.email-tmpl__paragraph {
  text-align: justify;
  color: #3b4143;
  color: var(--color-primary, #3b4143);
  font-family: 'Open Sans', sans-serif;
}
.email-tmpl__link {
  color: #ffb332 !important;
  color: var(--color-secondary, #ffb332) !important;  /* hover */
  font-weight: 700;
  text-decoration: underline;
  word-wrap: break-word;
}


/* EMAIL TEMPLATE GENERAL STYLES */
.email-tmpl__separator {
  border-top: 1px solid #eaeaea;
}


/* EMAIL TEMPLATE SPECIAL STYLES */
.email-tmpl__offer-wrapper {
  max-width: 20rem;
  margin: 0 auto 1rem;
}

.email-tmpl__banner-heading {
  color: white;
  font-size: 1.5rem;
  line-height: 1.2;
}
.email-tmpl__banner-text {
  color: white;
  line-height: 1.2;
}

@media (min-width: 768px) {
  .email-tmpl__banner-heading {
    font-size: 2rem;
  }
}

.top_top__2M5va {
  padding: .75rem 0;
  background-color: #3b4143;
  background-color: var(--color-primary, #3b4143);
  color: white;
  font-size: .8rem;
  line-height: 1;
}

.top_icon__3dTYc {
  margin-right: .25rem;
  color: #ffb332;
  color: var(--color-secondary, #ffb332);
  font-size: 1rem;
}

.navbar_navbar__2x0El {
  display: flex;
  align-items: center;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.24);
  padding: 1.5rem 0;
  background-color: white;
}

.navbar_logo__3SD_A {
  height: 1.5rem;
}

.menu_menu__2N4dM {
  display: flex;
  list-style: none;
  margin: 0;
  padding: 0;
}
.menu_menu__2N4dM > li {
  margin-left: 1.75rem;
}

.menu_link__3IF1Q {
  color: #ffb332 !important;
  color: var(--color-secondary, #ffb332) !important;  /* hover */
  font-weight: 500;
  text-decoration: underline;
}

.banner_banner__1ULkw {
  display: flex;
  align-items: flex-end;
  height: 35vh;
  padding-bottom: 1rem;
  background-image: url(/static/media/background.c91de5a9.jpg);
  background-size: cover;
  background-position: center;
}


@media (min-width: 768px) {
  .banner_banner__1ULkw {
    background-position: center 35%;
    padding-bottom: 2rem;
  }
}

@media (min-width: 992px) {
  .banner_banner__1ULkw {
    height: 40vh;
  }
}

.footer_footer__1-Mt4 {
  padding-top: 2rem;
  padding-bottom: 1rem;
  background-color: #3b4143;
  background-color: var(--color-primary, #3b4143);
  color: white;
}

.footer_paragraph__2hqzV {
  margin-bottom: .75rem;
  font-size: .8rem;
  line-height: 1.2;
}

.footer_link__vy5_m {
  color: #ffb332 !important;
  color: var(--color-secondary, #ffb332) !important; /* hover */
  text-decoration: underline;
}

.footer_social_link__3RbIk {
  color: #ffb332 !important;
  color: var(--color-secondary, #ffb332) !important;  /* hover */
  font-size: 2.2rem;
}

/* COPIED STYLES */

/* offer */
.offer_image_wrapper__2Motp {
  position: relative;
  height: 0;
  margin-bottom: 1rem;
  padding-bottom: 100%;
}
.offer_image__2ggXV {
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}

.offer_title__1anfg {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  height: 2.5rem;
  color: #3b4143;
  color: var(--color-primary, #3b4143);
  font-size: 1.35rem;
  font-weight: 700;
  text-transform: uppercase;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.offer_description__3tCNZ {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
  text-align: left;
  height: 2.5rem;
  margin-bottom: .75rem;
  color: #3b4143;
  color: var(--color-primary, #3b4143);
}

.offer_price_range__1XhCG {
  display: flex;
  align-items: center;
  color: #e6e6e6;
  font-size: 1.35rem;
  font-weight: 500;
}
.offer_price_muted__2lT5n {
  display: inline-block;
  width: 6rem;
  height: 1.5rem;
  border-radius: 1rem;
  margin: 0 .5rem;
  background-color: #e6e6e6;
}

.how-it-works_image_wrapper__3Lua2 {
  max-height: 12rem;
}

.how-it-works_image__1R7dT {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}


@media (min-width: 768px) {
  .how-it-works_image_wrapper__3Lua2 {
    max-height: 20rem;
  }
}


@media (min-width: 992px) {
  .how-it-works_image_wrapper__3Lua2 {
    max-height: none;
  }
}

