.operator {
  text-align: center;
  padding: 1rem 0;
}

.heading {
  margin-bottom: 1.5rem;
  color: var(--dshb-color-primary, #3b4143);
  font-size: 1.5rem;
  font-weight: 700;
}

.name {
  margin-bottom: .25rem;
  color: #6f6f6f;
  font-size: 1.25rem;
  font-weight: 500;
}

.phone_block {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 2rem;
}
.phone_text {
  margin-left: .5rem;
  color: var(--dshb-color-primary, #3b4143);
  font-size: 1rem;
  font-weight: 700;
}
