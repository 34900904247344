.about-info {
  padding-top: 3rem;
  padding-bottom: 1rem;
}

.about-info__heading {
  margin-bottom: 1.5rem;
  color: var(--color-primary, #073547);
  font-size: 2rem;
  font-weight: 500;
  line-height: 1.2;
}

.about-info__paragraph {
  margin-bottom: 2rem;
  color: var(--color-primary, #073547);
  font-family: 'Open Sans', sans-serif;
}

.about-info__link {
  color: var(--color-secondary, #ffb332) !important;
  font-weight: 700;
  text-decoration: underline;
}

@media (min-width: 992px) {
  .about-info {
    padding-top: 6rem;
    padding-bottom: 4rem;
  }
  .about-info__heading {
    margin-bottom: 2rem;
    font-size: 2.75rem;
  }
  .about-info__paragraph {
    font-size: 1.25rem;
  }
}

@media (min-width: 1200px) {
  .about-info {
    padding-right: 7rem;
  }
}
